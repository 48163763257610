import React from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Tab,
  InputGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";
import { pencairanAll } from "stores/Pengajuan/function";

function DetailSupervisor(data) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const detailValue = data.data;
  const [listPengajuanSpv, setListPengajuanSpv] = React.useState([]);

  const handleBackClick = () => {
    dispatch({ type: "SET_DETAIL_INSENTIVE_SPV", data: true });
  };

  React.useEffect(() => {
    let tmp =
      data.pengajuan &&
      data.pengajuan.map((val, index) => {
        // console.log(val, "VALL");
        return {
          ...val,
          action: <></>,
        };
      });
    setListPengajuanSpv(tmp);
  }, [data.pengajuan]);

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
  };

  const colPengajuan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => index + pengajuan.listPengajuan.pagingCounter,
      // (currentPage - 1) * totalPerHalaman +
      // pengajuan.listPengajuan.pagingCounter,
    },
    {
      name: "Referensi ID",
      minWidth: "390px",
      center: true,
      selector: (row) => row.id_pinjaman,
    },
    {
      name: "Calon Nasabah",
      minWidth: "270px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "Cabang Pencairan",
      minWidth: "250px",
      center: true,
      selector: (row) => row.namaCabangPencairan,
    },
    {
      name: "Marketing",
      minWidth: "250px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Supervisor",
      minWidth: "250px",
      center: true,
      selector: (row) => row.supervisor.nama,
    },
    {
      name: "Tanggal Pengajuan Ke Supervisor",
      minWidth: "300px",
      center: true,
      selector: (row) => moment(new Date(row.createdAt)).format("DD MMMM YYYY"),
    },
    {
      name: "Tanggal Pengajuan Ke BPD",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPengajuanBpd,
    },
    {
      name: "Tanggal Pencairan",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPencairan,
    },
    // {
    //   name: "Diupdate Oleh",
    //   minWidth: "300px",
    //   center: true,
    //   selector: (row) => row.diupdateOleh,
    // },
    {
      name: "Status",
      minWidth: "300px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  return (
    <>
      <Container fluid>
        <Card style={{ border: 0 }}>
          <Card.Body>
            <Button
              className="btn-transparent mr-2"
              type="button"
              variant="primary"
              size="md"
              style={{
                border: "none",
                backgroundColor: "#00BFFF",
                border: 0,
              }}
              onClick={handleBackClick}
            >
              <i className="fas fa-arrow-left"></i>
            </Button>
          </Card.Body>
          <Row>
            <Col md="12">
              <Card style={{ border: 0 }}>
                <Card.Header style={{ textAlign: "center" }}>
                  <Card.Title
                    as="h4"
                    style={{
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Detail insentive Marketing
                    <hr></hr>
                  </Card.Title>
                </Card.Header>

                <Card.Body>
                  <Card style={{ border: 0 }}>
                    <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
                      <div className="row">
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nama
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.nama}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            NIK
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.nik}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            Nopeg
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.nopeg}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            Jabatan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.role === "supervisor"
                                ? "Supervisor"
                                : "Supervisor"}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            kode user
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.kodeUser}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            terakhir login
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {moment(detailValue.loginDate).format(
                                "DD MMMM YYYY"
                              )}
                            </Label>
                          </li>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Card.Body>
              </Card>
              <Card
                style={{
                  border: 0,
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 30px",
                  // borderRadius: 20,
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                <Card.Header style={{ textAlign: "center" }}>
                  <Card.Title
                    as="h4"
                    style={{
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    List Pengajuan
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
                  {detailValue.pengajuan.length > 0 ? (
                    <DataTable
                      columns={colPengajuan}
                      data={data.pengajuan}
                      customStyles={customStyles}
                      // paginationPerPage={totalPerHalaman}
                      // progressPending={pending}
                    />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          fontWeight: "bold",
                          marginBottom: "1rem",
                        }}
                      >
                        Maaf, belum ada pengajuan baru saat ini.
                      </p>
                      <img
                        src={require("assets/img/sorry.jpg").default}
                        alt="Sorry"
                        style={{
                          maxWidth: "20%",
                          display: "block",
                          margin: "0 auto",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}

export default DetailSupervisor;
