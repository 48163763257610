import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import Moment from "moment";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  Modal,
  ModalBody,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { number } from "prop-types";
import { getAllPencairan } from "stores/Pengajuan/function";
import { InfinitySpin, Oval, Hourglass } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";

function Pencairan() {
  const dataPencairan = useSelector((state) => state.pengajuanReducer);
  const [modalDetails, setModalDetails] = React.useState(false);
  const [detailPencairan, setDetailPencairan] = React.useState([]);
  const dispatch = useDispatch();
  const [listPencairan, setListPencairan] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  Moment.locale("id");
  const tanggalAkad = Moment(detailPencairan.tglAkad).format("DD MMMM YYYY");
  const tanggalAgenda = Moment(detailPencairan.tglAgenda).format(
    "DD MMMM YYYY"
  );
  const [pending, setPending] = React.useState(false);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleFilterChange = () => {
    setPending(true);
    getAllPencairan(dispatch, {
      status: "Disetujui",
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  React.useEffect(() => {
    if (dataPencairan.listPencairan.data) {
      let tmp = dataPencairan.listPencairan.data.map((val) => {
        return {
          ...val,
          namaCabangPencairan: !val.lokasiPencairan
            ? "-"
            : val.lokasiPencairan.namaCabang,
          nominalPermohonan: !val.nominalPermohonan
            ? "-"
            : val.stepEmpat.nominalPermohonan,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Details Pencairan</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#65869F",
                    border: 0,
                  }}
                  onClick={() => {
                    setModalDetails(true);
                    setDetailPencairan(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
            </>
          ),
        };
      });
      setListPencairan(tmp);
    }
  }, [dataPencairan.listPencairan]);

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPencairan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + dataPencairan.listPencairan.pagingCounter,
    },
    {
      name: "Refference ID",
      minWidth: "350px",
      center: true,
      selector: (row) => row.id_pinjaman,
    },
    {
      name: "Nasabah",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "KTP",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nomorIdentitas,
    },
    {
      name: "Nominal Permohonan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.stepEmpat.nominalPermohonan);
        return formattedValue;
      },
    },
    {
      name: "Nominal Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalPencairan);
        return formattedValue;
      },
    },
    {
      name: "Cabang Realisasi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaCabangPencairan,
    },
    {
      name: "No Akad",
      minWidth: "200px",
      center: true,
      selector: (row) => (row.noAkad == "" ? "-" : row.noAkad),
    },
    {
      name: "Tanggal Akad",
      minWidth: "250px",
      center: true,
      sortable: true,
      selector: (row) => moment(new Date(row.tglAkad)).format("DD MMMM YYYY"),
    },
    {
      name: "No Agenda",
      minWidth: "200px",
      center: true,
      selector: (row) => (row.noAgenda === "" ? "-" : row.noAgenda),
    },
    {
      name: "Tanggal Agenda",
      minWidth: "200px",
      center: true,
      selector: (row) => moment(new Date(row.tglAgenda)).format("DD MMMM YYYY"),
    },
    {
      name: "Detail",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setPending(true);
    getAllPencairan(dispatch, {
      status: "Disetujui",
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then(() => {
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);

  return (
    <>
      <Modal
        size="lg"
        show={modalDetails}
        onHide={() => {
          // handleModalClose();
          setModalDetails(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12">
          <Card.Title
            as="h4"
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontFamily: "sans-serif",
              marginLeft: 13,
              textAlign: "center",
            }}
          >
            detail pencairan
            <hr></hr>
          </Card.Title>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nasabah
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.namaLengkap}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nominal Permohonan
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {!detailPencairan.stepEmpat
                        ? ""
                        : `Rp ${detailPencairan.stepEmpat.nominalPermohonan.toLocaleString(
                            "id-ID"
                          )}`}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nominal pencairan
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {!detailPencairan.nominalPencairan
                        ? "-"
                        : `Rp ${detailPencairan.nominalPencairan.toLocaleString(
                            "id-ID"
                          )}`}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      No Akad
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.noAkad === ""
                        ? "-"
                        : detailPencairan.noAkad}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Tanggal Akad
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {tanggalAkad}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      No Agenda
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.noAgenda === ""
                        ? "-"
                        : detailPencairan.noAgenda}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Tanggal Agenda
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {tanggalAgenda}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      KTP
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.nomorIdentitas}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Refference ID
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.id_pinjaman}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Cabang Realisasi
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {!detailPencairan.lokasiPencairan
                        ? ""
                        : detailPencairan.lokasiPencairan.namaCabang}
                    </label>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  pencairan
                </Card.Title>
                {/* <p className="card-category">Plain text tabs</p> */}
                <Tab.Content>
                  <Card style={{ marginTop: 20, border: 0 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col md={"4"}>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            onKeyDown={handleKeyDown}
                            placeholder="Reffrence ID/Nasabah"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearch}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                      <Col md={"8"}>
                        <Button
                          // variant="info"
                          style={{
                            fontSize: 16,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingTop: 10,
                            paddingBottom: 8,
                          }}
                          onClick={handleFilterChange}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Tab.Content>
              </Card.Header>
              <Card.Body>
                {pending ? (
                  <div
                    className="loader-container"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ marginBottom: 20 }}>
                      <Hourglass
                        visible={true}
                        height="50"
                        width="50"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={["#306cce", "#72a1ed"]}
                      />
                      {/* <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 19,
                      }}
                    >
                      Loading ....
                    </p> */}
                    </div>
                  </div>
                ) : (
                  <div>
                    <DataTable
                      columns={colPencairan}
                      data={listPencairan}
                      // {listPencairan.filter(
                      //   (row) =>
                      //     row.id_pinjaman
                      //       .toLowerCase()
                      //       .includes(searchText.toLowerCase()) ||
                      //     row.namaLengkap
                      //       .toLowerCase()
                      //       .includes(searchText.toLowerCase())
                      // )}
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalaman}
                      progressPending={pending}
                    />
                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-items-containers"
                            previousLinkClassName="pages-links-labels"
                            nextClassName="pages-items-containers"
                            nextLinkClassName="pages-links-labels"
                            breakClassName="pages-items-containers"
                            pageCount={dataPencairan.listPencairan.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labels"
                            pageClassName="pages-items-containers"
                            activeClassName="activees"
                            activeLinkClassName="activess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Pencairan;
