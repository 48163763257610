const initialState = {
  getDataPersenMf: [],
  getPaginationPersenMf: [],
  getDataPersenIns: [],
  getPaginationPersenIns: [],
  listManagementFee: [],
};

const managementFeeReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALL_PERSENMF":
      return {
        ...state,
        getDataPersenMf: data,
      };
    case "SET_ALL_PAGINATION_PERSENMF":
      return {
        ...state,
        getPaginationPersenMf: data,
      };
    case "SET_ALL_PERSEN_INSENTIVE":
      return {
        ...state,
        getDataPersenIns: data,
      };
    case "SET_ALL_PAGINATION_PERSEN_INSENTIVE":
      return {
        ...state,
        getPaginationPersenIns: data,
      };
    case "SET_ALL_GET_MANAGEMENT_FEE":
      return {
        ...state,
        listManagementFee: data,
      };
    default:
      return state;
  }
};
export default managementFeeReducer;
