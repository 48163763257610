import React from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Tab,
  Nav,
  InputGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";
import { pencairanAll } from "stores/Pengajuan/function";
// import NewAM from "../TabPenggajianUser/DetailPengajuanAM/newAM";
// import TopUpAM from "../TabPenggajianUser/DetailPengajuanAM/topUpAM";
// import KompensasiAM from "../TabPenggajianUser/DetailPengajuanAM/kompensasiAM";
// import TakeOverAM from "../TabPenggajianUser/DetailPengajuanAM/takeOverAM";
// import PelunasanDiPengajuanAM from "../TabPenggajianUser/DetailPengajuanAM/pelunasanDiPengajuanAM";

function DetailAreaManager(data) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const detailValue = data.data;
  const pengajuanDetails = detailValue.pengajuan;

  const [listPengajuanSM, setListPengajuanSM] = React.useState([]);

  const handleBackClick = () => {
    dispatch({ type: "SET_DETAIL_INSENTIVE_AM", data: true });
  };

  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };
  const totalPengajuan = Object.values(pengajuanDetails).reduce(
    (key, totalValue) => key + totalValue.pengajuan.length,
    0
  );

  // for (const key in pengajuanDetails) {
  //   if (pengajuanDetails.hasOwnProperty(key)) {
  //     console.log(`Pengajuan ${key}:`, pengajuanDetails[key]);
  //   }
  // }

  //   React.useEffect(() => {
  //     if (Array.isArray(pengajuanDetails)) {
  //       pengajuanDetails.forEach((item) => {
  //         if (item.pengajuan && typeof item.pengajuan === "object") {
  //           Object.entries(item.pengajuan).forEach(([key, val]) => {
  //             console.log(`Key: ${key}, Value: `, val);
  //           });
  //         }
  //       });
  //     }
  //   }, [pengajuanDetails]);

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
  };

  const colPengajuan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => index + pengajuan.listPengajuan.pagingCounter,
      // (currentPage - 1) * totalPerHalaman +
      // pengajuan.listPengajuan.pagingCounter,
    },
    {
      name: "Referensi ID",
      minWidth: "390px",
      center: true,
      selector: (row) => row.id_pinjaman,
    },
    {
      name: "Calon Nasabah",
      minWidth: "270px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "Cabang Pencairan",
      minWidth: "250px",
      center: true,
      selector: (row) => row.namaCabangPencairan,
    },
    {
      name: "Marketing",
      minWidth: "250px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Supervisor",
      minWidth: "250px",
      center: true,
      selector: (row) => row.supervisor.nama,
    },
    {
      name: "Tanggal Pengajuan Ke Supervisor",
      minWidth: "300px",
      center: true,
      selector: (row) => moment(new Date(row.createdAt)).format("DD MMMM YYYY"),
    },
    {
      name: "Tanggal Pengajuan Ke BPD",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPengajuanBpd,
    },
    {
      name: "Tanggal Pencairan",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPencairan,
    },
    // {
    //   name: "Diupdate Oleh",
    //   minWidth: "300px",
    //   center: true,
    //   selector: (row) => row.diupdateOleh,
    // },
    {
      name: "Status",
      minWidth: "300px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  return (
    <>
      <Container fluid>
        <Card style={{ border: 0 }}>
          <Card.Body>
            <Button
              className="btn-transparent mr-2"
              type="button"
              variant="primary"
              size="md"
              style={{
                border: "none",
                backgroundColor: "#00BFFF",
                border: 0,
              }}
              onClick={handleBackClick}
            >
              <i className="fas fa-arrow-left"></i>
            </Button>
          </Card.Body>
          <Row>
            <Col md="12">
              <Card style={{ border: 0 }}>
                <Card.Header style={{ textAlign: "center" }}>
                  <Card.Title
                    as="h4"
                    style={{
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Detail insentive Area Manager
                    <hr></hr>
                  </Card.Title>
                </Card.Header>

                <Card.Body>
                  <Card style={{ border: 0 }}>
                    <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
                      <div className="row">
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nama
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.nama}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            NIK
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.nik}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            Nopeg
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.nopeg}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            Jabatan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.role === "area manager"
                                ? "Area Manager"
                                : "Area Manager"}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            kode user
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.kodeUser}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            terakhir login
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {moment(detailValue.loginDate).format(
                                "DD MMMM YYYY"
                              )}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            status
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {detailValue.status === "aktif"
                                ? "Aktif"
                                : "Tidak Aktif"}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            total insentive
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {formatRupiah(detailValue.totalInsentive)}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            total insentive
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {formatRupiah(detailValue.totalNominal)}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            total Pengajuan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "none",
                              }}
                            >
                              {totalPengajuan} Pengajuan
                            </Label>
                          </li>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Card.Body>
              </Card>
              {/* <Card
                style={{
                  border: 0,
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 30px",
                  // borderRadius: 20,
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                <Card.Header style={{ textAlign: "center" }}>
                  <Card.Title
                    as="h4"
                    style={{
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    List Pengajuan
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
              </Card> */}
            </Col>
          </Row>
        </Card>
        {/* <hr></hr>
        <Card.Body>
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            <Tab.Container id="plain-tabs-example" defaultActiveKey={"new"}>
              <Nav role="tablist" variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="new" style={{ color: "#3ABEEA" }}>
                    New
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="topUp" style={{ color: "#3ABEEA" }}>
                    Top Up
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="kompensasi" style={{ color: "#3ABEEA" }}>
                    Kompensasi
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="takeOver" style={{ color: "#3ABEEA" }}>
                    Take Over
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="pelunasanDiPengajuan"
                    style={{ color: "#3ABEEA" }}
                  >
                    Pelunasan di pengajuan
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="new">
                  <NewAM data={data} />
                </Tab.Pane>
                <Tab.Pane eventKey="topUp">
                  <TopUpAM />
                </Tab.Pane>

                <Tab.Pane eventKey="kompensasi">
                  <KompensasiAM />
                </Tab.Pane>

                <Tab.Pane eventKey="takeOver">
                  <TakeOverAM />
                </Tab.Pane>
                <Tab.Pane eventKey="pelunasanDiPengajuan">
                  <PelunasanDiPengajuanAM />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body> */}
      </Container>
    </>
  );
}

export default DetailAreaManager;
