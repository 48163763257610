import React from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import CurrencyInput from "react-currency-input-field";
import { getAllClient } from "stores";
import { tambahTargetAreaManager } from "stores";
import { getAllAreaManager } from "stores";

function InputTargetAreaManager() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const areaManager = useSelector((state) => state.areaManagerReducer);
  const storeClient = useSelector((state) => state.clientReducer);
  const [listClient, setListClient] = React.useState([]);
  const [idClient, setIdClient] = React.useState("");
  const [tanggal, setTanggal] = React.useState("");
  const [listAreaManager, setListAreaManager] = React.useState([]);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [filterClient, setFilterClient] = React.useState(null);
  const [statusOmit, setStatusOmit] = React.useState(true);
  const [inputDisable, setInputDisable] = React.useState(true);
  // const [currentPage, setCurrentPage] = React.useState(1);
  // const itemsPerPage = 10;

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  const options = listClient;
  // const options =
  //   auth.role === "super admin"
  //     ? [{ value: "", label: "All" }, ...listClient]
  //     : listClient;

  const handleFilterChange = (selectedOption) => {
    setIdClient(selectedOption.value);
    setFilterClient(selectedOption);
    setInputDisable(false);
    getAllAreaManager(dispatch, { idClient: selectedOption.value });
  };

  const submitTargetAreaManager = () => {
    setStatusOmit(false);
    if (tanggal === "" || idClient === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Harap lengkapi tanggal dan Pilih BPD",
      });
    } else {
      let successCount = 0;
      let errorCount = 0;
      const totalTargets = listAreaManager.length;
      listAreaManager.forEach((val, index) => {
        setTimeout(function () {
          tambahTargetAreaManager({
            tanggal: tanggal,
            nominal: val.nominal,
            idAreaManager: val._id,
            idClient: idClient,
          }).then((response) => {
            let array = [...listAreaManager];
            if (response.data.status === 200) {
              array[index].status = "berhasil";
              successCount++;
            } else {
              array[index].status = "gagal";
              errorCount++;
            }
            setListAreaManager(array);
            if (index === totalTargets - 1) {
              if (successCount === totalTargets) {
                Swal.fire({
                  icon: "success",
                  title: "Sukses",
                  text: "Semua target berhasil di set",
                }).then(() => {
                  dispatch({
                    type: "SET_STATUS_INPUT_TARGET_AREAMANAGER",
                    data: true,
                  });
                });
              } else if (errorCount === totalTargets) {
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  text: "Target bulan ini sudah ada, silahkan set target dibulan lainnya.",
                }).then(() => {
                  dispatch({
                    type: "SET_STATUS_INPUT_TARGET_AREAMANAGER",
                    data: true,
                  });
                });
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "Peringatan",
                  text: "Beberapa target berhasil di set, namun ada yang gagal.",
                }).then(() => {
                  dispatch({
                    type: "SET_STATUS_INPUT_TARGET_AREAMANAGER",
                    data: true,
                  });
                });
              }
            }
          });
        }, 1000 * index);
      });
    }
  };

  React.useEffect(() => {
    let tmp =
      areaManager.listAreaManager &&
      areaManager.listAreaManager.map((val) => ({
        ...val,
        nominal: 0,
        status: null,
      }));
    setListAreaManager(tmp);
  }, [areaManager.listAreaManager]);

  // React.useEffect(() => {
  //   let tmp =
  //     areaManager.listAreaManager &&
  //     areaManager.listAreaManager.map((val) => {
  //       return {
  //         ...val,
  //         nominal: 0,
  //         status: null,
  //         action: (
  //           <>
  //             {" "}
  //             <Button
  //               className="mr-0"
  //               variant="info"
  //               style={{ marginTop: 5 }}
  //               onClick={() => setModalEdit(!modalEdit)}
  //             >
  //               Edit
  //             </Button>
  //           </>
  //         ),
  //       };
  //     });
  //   setListAreaManager(tmp);
  // }, [areaManager.listAreaManager]);

  React.useEffect(() => {
    let tmp = [
      // {
      //   value: "",
      //   isDisabled: true,
      // },
    ];
    storeClient.listClient &&
      storeClient.listClient.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaClient,
        });
      });
    setListClient(tmp);
  }, [storeClient.listClient]);

  const handleClick = () => {
    dispatch({ type: "SET_STATUS_INPUT_TARGET_AREAMANAGER", data: true });
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colTargetAreaManager = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) => index + 1,
    },
    {
      name: "No Karyawan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Nama Area Manager",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Nominal",
      minWidth: "200px",
      center: true,
      cell: (row, index) => (
        <CurrencyInput
          style={{ padding: 5, borderRadius: 5 }}
          name="nominal"
          placeholder="Masukkan nominal"
          disabled={inputDisable}
          prefix="Rp "
          onValueChange={(value) => {
            setListAreaManager([
              ...listAreaManager.slice(0, index),
              { ...row, nominal: value },
              ...listAreaManager.slice(index + 1),
            ]);
          }}
        />
      ),
    },
    {
      name: "Status",
      minWidth: "200px",
      center: true,
      omit: statusOmit,
      selector: (row) =>
        row.status === null ? (
          "Loading......."
        ) : row.status === "berhasil" ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
        ) : row.status === "loading" ? (
          "Loading..."
        ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
        ),
    },
    // {
    //   name: "Aksi",
    //   minWidth: "200px",
    //   center: true,
    //   selector: (row) => row.action,
    // },
  ];

  React.useEffect(() => {
    getAllAreaManager(dispatch, { idClient: "" });
    getAllClient(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: "inherit",
                  }}
                >
                  Set Target Area Manager
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Card style={{ border: 0 }}>
                  <Row>
                    <Col md={"3"}>
                      <label style={{ marginLeft: 15, color: "black" }}>
                        Tanggal
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setTanggal(e.target.value + "-01");
                        }}
                        placeholder="Input Tanggal"
                        type="month"
                      ></Form.Control>
                    </Col>
                    <Col md={"3"}>
                      <label style={{ color: "black" }}>Pilih Client</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Client"
                        value={filterClient}
                        onChange={(selectedOption) =>
                          handleFilterChange(selectedOption)
                        }
                        options={options}
                        placeholder="Pilih Client"
                      />
                    </Col>
                    <Col style={{ marginTop: 25, marginLeft: 20 }}>
                      <div>
                        <Button
                          className=" mr-1"
                          variant="danger"
                          style={{}}
                          onClick={handleClick}
                        >
                          Cancel
                        </Button>
                        <Button
                          className=" mr-1"
                          variant="success"
                          style={{
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                          }}
                          onClick={submitTargetAreaManager}
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <DataTable
                  columns={colTargetAreaManager}
                  data={listAreaManager}
                  customStyles={customStyles}
                  // pagination
                  // paginationPerPage={itemsPerPage}
                  // onChangePage={handlePageChange}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default InputTargetAreaManager;
