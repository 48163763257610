const initialState = {
  modalDetailPengajuan: true,
  pengajuanLama: true,
  modalDetailInsentive: true,
  modalDetailInsentiveSpv: true,
  modalDetailInsentiveAM: true,
  listPengajuan: [],
  listPengajuanBestada: [],
  listPengajuanById: [],
  listPencairan: [],
  listStatus: [],
  listTotalTargetUser: [],
  detailPengajuan: [],
  detailInsentive: [],
  totalPencairan: [],
  pencairanALLData: [],
  pencairanALLDataSpv: [],
  pencairanALLDataAM: [],
  limitPagePencairanAll: [],
  limitPagePencairanAllSpv: [],
  limitPagePencairanAllAM: [],
  valueCol: "12",
  valueColPrev: "0",
  urlPreview: "",
  titlePreview: "",
};

const pengajuanReducer = (state = initialState, action) => {
  // console.log(initialState, "INITIAL STATE");
  let { type, data } = action;
  switch (type) {
    case "SET_STEP_PENGAJUAN":
      return {
        ...state,
        modalDetailPengajuan: data,
      };
    case "SET_PENGAJUAN_LAMA":
      return {
        ...state,
        pengajuanLama: data,
      };
    case "SET_ALL_STATUS":
      return {
        ...state,
        listStatus: data,
      };
    case "SET_ALL_TOTAL_USER":
      return {
        ...state,
        listTotalTargetUser: data,
      };
    case "SET_ALL_PENGAJUAN":
      return {
        ...state,
        listPengajuan: data,
      };
    case "SET_ALL_PENGAJUAN_BESTADA":
      return {
        ...state,
        listPengajuanBestada: data,
      };
    case "SET_ALL_PENGAJUAN_BY_ID":
      return {
        ...state,
        listPengajuanById: data,
      };
    case "SET_ALL_PENGAJUAN_BESTADA_BY_ID":
      return {
        ...state,
        listPengajuanById: data,
      };
    case "SET_ALL_PENCAIRAN":
      return {
        ...state,
        listPencairan: data,
      };
    case "SET_ALL_TOTAL_PENCAIRAN":
      return {
        ...state,
        totalPencairan: data,
      };
    case "SET_DETAIL_PENGAJUAN":
      return {
        ...state,
        detailPengajuan: data,
      };
    case "SET_PENCAIRAN_ALL":
      return {
        ...state,
        pencairanALLData: data,
      };
    case "SET_LIMIT_PAGE":
      return {
        ...state,
        limitPagePencairanAll: data,
      };
    case "SET_PENCAIRAN_ALL_SPV":
      return {
        ...state,
        pencairanALLDataSpv: data,
      };
    case "SET_LIMIT_PAGE_SPV":
      return {
        ...state,
        limitPagePencairanAllSpv: data,
      };
    case "SET_PENCAIRAN_ALL_AM":
      return {
        ...state,
        pencairanALLDataAM: data,
      };
    case "SET_LIMIT_PAGE_AM":
      return {
        ...state,
        limitPagePencairanAllAM: data,
      };
    case "SET_DETAIL_PENGAJUAN_BESTADA":
      return {
        ...state,
        detailPengajuan: data,
      };
    case "SET_DETAIL_INSENTIVE":
      return {
        ...state,
        modalDetailInsentive: data,
      };
    case "SET_DETAIL_INSENTIVE_SPV":
      return {
        ...state,
        modalDetailInsentiveSpv: data,
      };
    case "SET_DETAIL_INSENTIVE_AM":
      return {
        ...state,
        modalDetailInsentiveAM: data,
      };
    case "SET_DATA_DETAIL_INSENTIVE":
      return {
        ...state,
        detailInsentive: data,
      };
    case "PREVIEW_DOKUMEN":
      return {
        ...state,
        valueCol: "6",
        valueColPrev: "6",
        urlPreview: data,
        titlePreview: data,
      };
    case "HIDE_DOKUMEN":
      return {
        ...state,
        valueCol: "12",
        valueColPrev: "0",
        urlPreview: "",
        titlePreview: "",
      };

    default:
      return state;
  }
};
export default pengajuanReducer;
