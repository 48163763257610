import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Label } from "reactstrap";
import { getAllTotalAreaManager } from "stores";
import { getAllTotalPengajuan } from "stores";
import ReactDatetime from "react-datetime";
import { getTotalPencairan } from "stores/Pengajuan/function";
import { getGrafikPencairanCabang } from "stores";
import moment from "moment";
import {
  Bar,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { getTotalTargetUser } from "stores/Pengajuan/function";
import { getGrafikPencairanUser } from "stores";
import { getAllTotalSupervisor } from "stores";
import { getAllTotalMarketing } from "stores";
import { getAllTotalCabang } from "stores";
import { getExportPengajuan } from "stores";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getListManagementFee } from "stores";

function DashboardUtama() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const cardTotalPengajuan = useSelector(
    (state) => state.dashboardUtamaReducer
  );
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const managementFee = useSelector((state) => state.managementFeeReducer);
  // const ambilManagementFee = managementFee.listManagementFee;
  const tampilTotalPengajuan = useSelector(
    (state) => state.dashboardUtamaReducer.totalAllPengajuan
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [modalExport, setModalExport] = React.useState(false);
  const [modalTotalPencairan, setModalTotalPencairan] = React.useState(false);
  const [modalMenunggu, setModalMenunggu] = React.useState(false);
  const [modalRevisi, setModalRevisi] = React.useState(false);
  const [modalDitolak, setModalDitolak] = React.useState(false);
  const [modalDiajukan, setModalDiajukan] = React.useState(false);
  const [modalDisetujui, setModalDisetujui] = React.useState(false);
  const [modalDiproses, setModalDiproses] = React.useState(false);
  const [modalDiajukanFileGagal, setModalDiajukanFileGagal] =
    React.useState(false);

  const itemsPerPage = 10;
  const [totalNominalPencairan, setTotalNominalPencairan] = React.useState(0);
  const [totalNominalBaru, setTotalNominalBaru] = React.useState(0);
  const [totalNominalKompensasi, setTotalNominalKompensasi] = React.useState(0);
  const [totalNominalTakeOver, setTotalNominalTakeOver] = React.useState(0);
  const [totalNominalPelunasan, setTotalNominalPelunasan] = React.useState(0);
  const [totalNominalTopUp, setTotalNominalTopUp] = React.useState(0);
  const [loadingPresentase, setLoadingPresentase] = React.useState(false);
  const [hasilPresentase, setHasilPresentase] = React.useState(0);
  const [totalNominalTarget, setTotalNominalTarget] = React.useState(0);
  const [dataGrafikCabang, setDataGrafikCabang] = React.useState([]);
  const [dataGrafikUser, setDataGrafikUser] = React.useState([]);
  const [totalSpv, setTotalSpv] = React.useState([]);
  const [totalCbg, setTotalCbg] = React.useState([]);
  const [totalAM, setTotalAM] = React.useState([]);
  const [totalMarketing, setTotalMarketing] = React.useState([]);
  const [dataAllGrafikCabang, setDataAllGrafikCabang] = React.useState([]);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglManagementFee, setTglManagementFee] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [status, setStatus] = React.useState("");
  const [modalDetail, setModalDetail] = React.useState(false);
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const pencairanData = dataGrafikCabang.map((item) => item.totalPencairan);
  const targetData = dataGrafikCabang.map((item) => item.totalTarget);
  const [tahun, setTahun] = React.useState(new Date().getFullYear());
  let yearNow = new Date().getFullYear();
  const [listYear, setListYear] = React.useState([]);
  const [listDataMF, setListDataMF] = React.useState([]);
  const bulanData = dataGrafikUser.map((item) => item.bulan);
  const pencairanDataUser = dataGrafikUser.map(
    (item) => item.totalNominalPencairan
  );
  const targetDataUser = dataGrafikUser.map((item) => item.totalNominalTarget);
  const abjadCabang = dataGrafikCabang.map((_, index) =>
    String.fromCharCode(65 + index)
  );
  const nominalBaruInBillion = totalNominalBaru / 1000000000;
  const nominalKompensasiInBillion = totalNominalKompensasi / 1000000000;
  const nominalPelunasanInBillion = totalNominalPelunasan / 1000000000;
  const nominalTakeOverInBillion = totalNominalTakeOver / 1000000000;
  const nominalTopUpInBillion = totalNominalTopUp / 1000000000;
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };

  const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);
  };

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
    setPage(0);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleExportPengajuan = () => {
    setStatus("");
    setTglAwal(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
        0,
        0,
        0,
        0
      )
    );
    setTglAkhir(new Date());
  };

  const closeModal = () => {
    setCurrentPage(1);
    setModalDetail(false);
    setModalTotalPencairan(false);
    setModalMenunggu(false);
    setModalRevisi(false);
    setModalDitolak(false);
    setModalDiajukan(false);
    setModalDiproses(false);
    setModalDisetujui(false);
    setModalDiajukanFileGagal(false);
  };

  const chartData = {
    labels: abjadCabang,
    series: [pencairanData, targetData],
  };

  const chartDataUser = {
    labels: bulanData,
    series: [pencairanDataUser, targetDataUser],
  };

  React.useEffect(() => {
    // setIsVisible(true);s
    getListYear();
  }, []);

  React.useEffect(() => {
    getAllTotalPengajuan(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  const submitExport = async (e) => {
    e.preventDefault();
    if (tglAwal === "" || tglAkhir === "" || status === "") {
      Swal.fire({
        title: "Semua Field Wajib Diisi",
        icon: "warning",
      });
      return;
    }

    Swal.fire({
      title: "Konfirmasi Export Pengajuan",
      text: `Apakah Anda ingin mengexport dari ${moment(tglAwal).format(
        "DD MMMM YYYY"
      )} sampai ${moment(tglAkhir).format("DD MMMM YYYY")} ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      // showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          Swal.fire({
            title: "Sedang Mengexport, Ditunggu yaa..",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          // Meminta server untuk ekspor data
          const response = await getExportPengajuan({
            tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
            tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
            status: status.value,
          });

          // Mengaktifkan unduhan setelah menerima respons dari server
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Export Pengajuan ${status.value}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          Swal.close();
          // Tampilkan swal.fire setelah unduhan selesai
          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  const getListYear = () => {
    let tmp = [];
    for (let i = 5; i >= 0; i--) {
      let years = yearNow--;
      tmp.push({
        value: years,
        label: years,
      });
    }
    setListYear(tmp);
  };

  React.useEffect(() => {
    setLoadingPresentase(true);
    getTotalPencairan(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      // console.log(response, "SSS");
      setLoadingPresentase(false);
      const totalPencairan = response.data.totalNominal;
      const totalBaru = response.data.baru;
      const totalKompensasi = response.data.kompensasi;
      const totalPelunasan = response.data.pelunasan;
      const totalTakeOver = response.data.takeOver;
      const totalTopUp = response.data.topup;

      setTotalNominalPencairan(totalPencairan);
      setTotalNominalBaru(totalBaru);
      setTotalNominalKompensasi(totalKompensasi);
      setTotalNominalPelunasan(totalPelunasan);
      setTotalNominalTakeOver(totalTakeOver);
      setTotalNominalTopUp(totalTopUp);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalTargetUser(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalTarget = response.data.totalTarget;
      setTotalNominalTarget(totalTarget);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanCabang(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const grafikCabang = response.data;
      let tmp = [];
      grafikCabang.map((val, index) => {
        if (index < 15) {
          tmp.push(val);
        }
      });
      setDataGrafikCabang(tmp);
      setDataAllGrafikCabang(grafikCabang);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanUser(dispatch, {
      tahun: tahun,
    }).then((response) => {
      const grafikUser = response.data;
      setDataGrafikUser(grafikUser);
    });
  }, [tahun]);

  React.useEffect(() => {
    if (totalNominalTarget !== 0 && totalNominalPencairan !== 0) {
      const presentase = Math.round(
        (totalNominalPencairan / totalNominalTarget) * 100
      );
      setHasilPresentase(presentase);
    } else {
      setHasilPresentase(0);
    }
  }, [totalNominalPencairan, totalNominalTarget]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colCabangPencairan = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Nama Cabang",
      minWidth: "150px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Total Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalPencairan.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Target",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalTarget.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
  ];

  React.useEffect(() => {
    if (managementFee.listManagementFee) {
      const tmp = managementFee.listManagementFee.map((val, index) => {
        return {
          ...val,
          action: <></>,
        };
      });
      setListDataMF(tmp);
    }
  }, [managementFee.listManagementFee]);

  const fmValues = managementFee.listManagementFee
    ? managementFee.listManagementFee.map((val) => Math.round(val.fm))
    : [];

  const items = [
    { title: "New", result: fmValues[0] || 0 },
    { title: "Top Up", result: fmValues[1] || 0 },
    { title: "Take Over", result: fmValues[2] || 0 },
    { title: "Kompensasi", result: fmValues[3] || 0 },
  ];

  const total = items.reduce((acc, item) => acc + item.result, 0);

  React.useEffect(() => {
    setLoading(true);
    getListManagementFee(dispatch, {
      filterTgl: moment(tglManagementFee).format("YYYY-MM-DD"),
    })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman, tglManagementFee]);

  const handleDateChange = (date) => {
    const newDate = date.toDate();
    newDate.setHours(0, 0, 0, 0);
    setTglManagementFee(
      new Date(newDate.getFullYear(), newDate.getMonth(), 1).getTime()
    );
  };

  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      className="form-control"
      value={moment(value).format("MMMM YYYY")}
      onClick={onClick}
      readOnly
      style={{ cursor: "default", color: "black", backgroundColor: "white" }}
    />
  );

  React.useEffect(() => {
    getAllTotalSupervisor(dispatch).then((response) => {
      const dataSupervisor = response.data;
      setTotalSpv(dataSupervisor);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalAreaManager(dispatch).then((response) => {
      const dataAreaManager = response.data;
      setTotalAM(dataAreaManager);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalMarketing(dispatch).then((response) => {
      const dataMarketing = response.data;
      setTotalMarketing(dataMarketing);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalCabang(dispatch).then((response) => {
      const dataCabang = response.data;
      setTotalCbg(dataCabang);
    });
  }, []);

  React.useEffect(() => {
    if (auth.role === "client") {
      const interval = setInterval(() => {
        window.location.reload();
        refresh();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [auth.role]);

  return (
    <>
      <Modal
        size="md"
        show={modalDiajukanFileGagal}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    new
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .diajukanBaruGagalFile}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    kompensasi
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .diajukanKompensasiGagalFile}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    pelunasan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .diajukanPelunasanGagalFile}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    take over
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .diajukanTakeOverGagalFile}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    top-up
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .diajukanTopupGagalFile}
                    </Label>
                  </li>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalMenunggu}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    new
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.menungguBaru}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    kompensasi
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .menungguKompensasi}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    pelunasan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .menungguPelunasan}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    take over
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.menungguTakeOver}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    top-up
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.menungguTopup}
                    </Label>
                  </li>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalRevisi}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    new
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.revisiBaru}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    kompensasi
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.revisiKompensasi}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    pelunasan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.revisiPelunasan}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    take over
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.revisiTakeOver}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    top-up
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.revisiTopup}
                    </Label>
                  </li>
                </div>
                {/* <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total revisi keseluruhan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.revisi}
                    </Label>
                  </li>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalDitolak}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    new
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.ditolakBaru}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    kompensasi
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .ditolakKompensasi}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    pelunasan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.ditolakPelunasan}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    take over
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.ditolakTakeOver}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    top-up
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.ditolakTopup}
                    </Label>
                  </li>
                </div>
                {/* <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total ditolak keseluruhan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.ditolak}
                    </Label>
                  </li>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalDiajukan}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    new
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.diajukanBaru}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    kompensasi
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .diajukanKompensasi}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    pelunasan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .diajukanPelunasan}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    take over
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.diajukanTakeOver}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    top-up
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.diajukanTopup}
                    </Label>
                  </li>
                </div>
                {/* <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total diajukan keseluruhan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.diajukan}
                    </Label>
                  </li>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalDiproses}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    new
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.prosesBaru}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    kompensasi
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.prosesKompensasi}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    pelunasan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.prosesPelunasan}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    take over
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.prosesTakeOver}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    top-up
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.prosesTopup}
                    </Label>
                  </li>
                </div>
                {/* <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    keseluruhan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.proses}
                    </Label>
                  </li>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalDisetujui}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    new
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.disetujuiBaru}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    kompensasi
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .disetujuiKompensasi}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    pelunasan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .disetujuiPelunasan}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    take over
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data
                          .disetujuiTakeOver}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    top-up
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.disetujuiTopup}
                    </Label>
                  </li>
                </div>
                {/* <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total disetujui keseluruhan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.disetujui}
                    </Label>
                  </li>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalTotalPencairan}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail Total Pencairan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total pencairan new
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(totalNominalBaru)}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total pencairan kompensasi
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(totalNominalKompensasi)}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total pencairan pelunasan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(totalNominalPelunasan)}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total pencairan take over
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(totalNominalTakeOver)}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total pencairan top-up
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(totalNominalTopUp)}
                    </Label>
                  </li>
                </div>
                {/* <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    total pencairan keseluruhan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {formatRupiah(totalNominalPencairan)}
                    </Label>
                  </li>
                </div> */}
              </div>
              <br></br>
              <div>
                <Container
                  style={{
                    border: 0,
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 30px",
                    borderRadius: 20,
                  }}
                >
                  <Card.Header>
                    <Card.Title
                      as="h3"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Grafik Total Pencairan
                      <hr></hr>
                    </Card.Title>
                  </Card.Header>
                  <VictoryChart
                    domainPadding={{ x: 50 }}
                    width={600}
                    height={400}
                    style={{ parent: { maxWidth: "100%" } }}
                  >
                    <VictoryBar
                      data={[
                        { category: "Baru", value: nominalBaruInBillion },
                        {
                          category: "Kompensasi",
                          value: nominalKompensasiInBillion,
                        },
                        {
                          category: "Pelunasan",
                          value: nominalPelunasanInBillion,
                        },
                        {
                          category: "Take Over",
                          value: nominalTakeOverInBillion,
                        },
                        { category: "Top Up", value: nominalTopUpInBillion },
                      ]}
                      x="category"
                      y="value"
                      style={{
                        data: {
                          fill: "#3C4151",
                          width: 20,
                        },
                        labels: { fontSize: 12, fill: "#333" },
                      }}
                    />
                    <VictoryAxis
                      label="Jenis Kredit"
                      tickLabelComponent={
                        <VictoryLabel style={{ fontSize: "12px" }} />
                      }
                      style={{
                        axisLabel: {
                          padding: 30,
                          fontSize: "12px",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        },
                      }}
                    />
                    <VictoryAxis
                      dependentAxis
                      tickCount={6}
                      label="Nominal Pencairan dalam Miliar"
                      style={{
                        axisLabel: {
                          padding: 37,
                          fontSize: "9px",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        },
                        tickLabels: {
                          fontSize: "12px",
                          // padding: 30,
                          // textAnchor: "start",
                        },
                      }}
                      domain={{
                        y: [
                          nominalBaruInBillion,
                          nominalKompensasiInBillion,
                          nominalPelunasanInBillion,
                          nominalTakeOverInBillion,
                          nominalTopUpInBillion,
                        ].some((value) => value !== 0)
                          ? undefined
                          : [0, 1],
                      }}
                    />
                  </VictoryChart>
                </Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalExport}
        onHide={() => {
          setModalExport(false);
          handleExportPengajuan();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card style={{ border: 0 }}>
                  <Card.Title
                    as="h4"
                    style={{ fontWeight: "bold", textTransform: "none" }}
                  >
                    Export Pengajuan
                    <hr></hr>
                  </Card.Title>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Awal
                          </label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0),
                                setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAwal}
                            initialValue={tglAwal}
                            timeFormat={false}
                          ></ReactDatetime>
                          {/* <Form.Control
                            onChange={(e) => {
                              setTglAwal(e.target.value);
                            }}
                            type="date"
                          /> */}
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Akhir
                          </label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(23, 59, 0, 0),
                                setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={tglAkhir}
                            initialValue={tglAkhir}
                            timeFormat={false}
                          ></ReactDatetime>
                          {/* <Form.Control
                            onChange={(e) => {
                              setTglAkhir(e.target.value);
                            }}
                            type="date"
                          /> */}
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Status
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatus(value)}
                            options={[
                              {
                                value: "",
                                label: "Pilih Status",
                                isDisabled: true,
                              },
                              {
                                value: "Semua",
                                label: "Semua Pengajuan",
                              },
                              {
                                value: "Disetujui",
                                label: "Disetujui",
                              },
                              {
                                value: "Diproses",
                                label: "Diproses",
                              },
                              {
                                value: "Diajukan",
                                label: "Diajukan",
                              },
                              {
                                value: "Menunggu Konfirmasi",
                                label: "Menunggu Konfirmasi",
                              },
                              {
                                value: "Revisi",
                                label: "Revisi",
                              },
                              {
                                value: "Ditolak",
                                label: "Ditolak",
                              },
                            ]}
                            placeholder="Pilih Status"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      // variant="info"
                      style={{
                        border: 0,
                        backgroundColor: "#77CA2A",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                      onClick={submitExport}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalDetail}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail Data Cabang Pencairan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <DataTable
                columns={colCabangPencairan}
                data={dataAllGrafikCabang}
                // .filter(
                //   (row) =>
                //     row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
                //     row.nopeg.toLowerCase().includes(searchText.toLowerCase())
                // )}
                customStyles={customStyles}
                pagination
                paginationPerPage={itemsPerPage}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Card style={{ borderRadius: 10 }}>
        <Container fluid>
          <Row>
            <Col md="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: 10,
                  marginTop: 15,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#67689E",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title as="h3">
                    <Row>
                      <Col
                        md="8"
                        className="p-3"
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>bpd kalsel</b>
                      </Col>
                      <Col md="4" className="p-2 d-flex justify-content-end">
                        <Image
                          src={
                            "https://ptbap.net/assets/images/logo/invert-bap.png"
                          }
                          width="auto"
                          height="50px"
                        />
                      </Col>
                    </Row>
                  </Card.Title>
                </Card.Header>
                {/* <Card.Body
                style={{
                  backgroundColor: "white",
                }}
              > */}
                <Row>
                  <Col
                    lg="5"
                    className="d-none d-lg-flex align-items-center justify-content-center"
                  >
                    <Image
                      src={
                        "https://images.bisnis.com/posts/2021/06/17/1406647/bank-kalsel.jpg"
                      }
                      width="200px"
                      height="auto"
                    />
                  </Col>
                  <Col lg="7" xs="12">
                    <div className="numbers d-flex justify-content-around">
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: 27,
                              }}
                            >
                              Pencapaian
                            </b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{
                            color:
                              hasilPresentase > 100 ? "#26DB53" : "#FF4D4F",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                          }}
                        >
                          {loadingPresentase === true ? (
                            <SkeletonTheme
                              color="#BDC7C9"
                              highlightColor="#BDC7C9"
                            >
                              <Skeleton height={"100%"} width={100} />
                            </SkeletonTheme>
                          ) : (
                            <b>{hasilPresentase}%</b>
                          )}
                          {/* <b>{hasilPresentase} %</b> */}
                        </Card.Title>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: 27,
                              }}
                            >
                              Target
                            </b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{
                            color: "#26DB53",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                          }}
                        >
                          <b>100%</b>
                        </Card.Title>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col lg="6" sm="6">
              <Row className="mb-3">
                <Col md="6">
                  <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                    Tanggal Awal
                  </h4>
                  <Form.Group
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        readOnly: true,
                        style: {
                          cursor: "default",
                          color: "black",
                          backgroundColor: "white",
                        },
                      }}
                      onChange={(e) => {
                        e.toDate().setHours(0, 0, 0, 0),
                          setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                      }}
                      value={dariTgl}
                      initialValue={dariTgl}
                      timeFormat={false}
                    ></ReactDatetime>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                    Tanggal Akhir
                  </h4>
                  <Form.Group
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        readOnly: true,
                        style: {
                          cursor: "default",
                          color: "black",
                          backgroundColor: "white",
                        },
                      }}
                      onChange={(e) => {
                        e.toDate().setHours(23, 59, 0, 0),
                          setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                      }}
                      value={smpTgl}
                      initialValue={smpTgl}
                      timeFormat={false}
                    ></ReactDatetime>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#67689E",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Total Pencairan</b>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Detail</Tooltip>}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() =>
                              setModalTotalPencairan(!modalTotalPencairan)
                            }
                          >
                            <i
                              class="fas fa-external-link-alt"
                              style={{
                                fontSize: 15,
                                color: "white",
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            ></i>
                          </div>
                        </OverlayTrigger>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {pengajuan.totalPencairan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={120} />
                        </SkeletonTheme>
                      ) : (
                        formatRupiah(pengajuan.totalPencairan)
                      )}
                      {/* {formatRupiah(totalNominalPencairan)} */}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#67689E",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Total Target</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {pengajuan.totalPencairan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={120} />
                        </SkeletonTheme>
                      ) : (
                        formatRupiah(pengajuan.listTotalTargetUser.totalTarget)
                      )}
                      {/* {`Rp ${totalNominalTarget.toLocaleString("id-ID")}`} */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          {auth.role === "super admin" || auth.role === "client" ? (
            <>
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: 10,
                  borderTopRightRadius: 15,
                  borderTopLeftRadius: 15,
                  marginTop: 15,
                }}
              >
                <Card.Header>
                  <Row className="align-items-center">
                    <Col md="4">
                      <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                        Pilih Tanggal
                      </h4>
                      <Form.Group
                        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                      >
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Date Picker Here",
                            readOnly: true,
                            style: {
                              cursor: "default",
                              color: "black",
                              backgroundColor: "white",
                            },
                          }}
                          dateFormat="MMMM YYYY"
                          timeFormat={false}
                          onChange={handleDateChange}
                          value={new Date(tglManagementFee)}
                          renderInput={(props, openCalendar) => (
                            <CustomInput {...props} onClick={openCalendar} />
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="8" className="text-start">
                      <h3
                        style={{
                          color: "black",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        Management Fee
                        <hr />
                      </h3>
                    </Col>
                    <div
                      style={{
                        position: "absolute",
                        top: "35px",
                        right: "20px",
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        padding: "10px 20px",
                        boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#527BB6" }}>
                        <strong>Total :</strong>{" "}
                        <strong>{formatRupiah(total)}</strong>
                      </span>
                    </div>
                  </Row>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col lg="12" sm="12">
                      <div className="numbers d-flex flex-wrap justify-content-around">
                        {items.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex flex-column align-items-center my-3 mx-2"
                          >
                            <h4 className="text-center">
                              <b
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                {item.title}
                                <div
                                  style={{
                                    borderBottom: "3px solid grey",
                                    marginTop: 20,
                                  }}
                                ></div>
                              </b>
                            </h4>

                            <div style={{ textAlign: "center" }}>
                              {loadingPresentase ? (
                                <SkeletonTheme
                                  color="#BDC7C9"
                                  highlightColor="#BDC7C9"
                                >
                                  <Skeleton height={50} width={100} />
                                </SkeletonTheme>
                              ) : (
                                <b>{formatRupiah(item.result)}</b>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          ) : null}

          {/* <Row>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Menunggu</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  className="ct-perfect-fourth"
                  data={{
                    labels: ["40%", "20%", "40%"],
                    series: [40, 20, 40],
                  }}
                  type="Pie"
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Open <i className="fas fa-circle mr-1 text-danger"></i>
                  Bounce <i className="fas fa-circle mr-1 text-warning"></i>
                  Unsubscribe
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Revisi</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  className="ct-perfect-fourth"
                  data={{
                    labels: ["40%", "20%", "40%"],
                    series: [40, 20, 40],
                  }}
                  type="Pie"
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Open <i className="fas fa-circle mr-1 text-danger"></i>
                  Bounce <i className="fas fa-circle mr-1 text-warning"></i>
                  Unsubscribe
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Footer>
            </Card>
          </Col>

          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Ditolak</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  className="ct-perfect-fourth"
                  data={{
                    labels: ["40%", "20%", "40%"],
                    series: [40, 20, 40],
                  }}
                  type="Pie"
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Open <i className="fas fa-circle mr-1 text-danger"></i>
                  Bounce <i className="fas fa-circle mr-1 text-warning"></i>
                  Unsubscribe
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Diajukan</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  className="ct-perfect-fourth"
                  data={{
                    labels: ["40%", "20%", "40%"],
                    series: [40, 20, 40],
                  }}
                  type="Pie"
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Open <i className="fas fa-circle mr-1 text-danger"></i>
                  Bounce <i className="fas fa-circle mr-1 text-warning"></i>
                  Unsubscribe
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Diproses</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  className="ct-perfect-fourth"
                  data={{
                    labels: ["40%", "20%", "40%"],
                    series: [40, 20, 40],
                  }}
                  type="Pie"
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Open <i className="fas fa-circle mr-1 text-danger"></i>
                  Bounce <i className="fas fa-circle mr-1 text-warning"></i>
                  Unsubscribe
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Disetujui</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <div style={{ width: "100%", height: 250 }}>
                  <VictoryChart
                    height={400}
                    width={400}
                    domainPadding={{ x: 50, y: [0, 20] }}
                    scale={{ x: "time" }}
                  >
                    <VictoryBar
                      style={{
                        data: { fill: "tomato" },
                      }}
                      data={[
                        { x: new Date(1986, 1, 1), y: 2 },
                        { x: new Date(1996, 1, 1), y: 3 },
                        { x: new Date(2006, 1, 1), y: 5 },
                        { x: new Date(2016, 1, 1), y: 4 },
                      ]}
                    />
                  </VictoryChart>
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i
                    className="fas fa-circle mr-0 "
                    style={{ color: "tomato" }}
                  ></i>
                  New
                  <i
                    className="fas fa-circle ml-2 "
                    style={{ color: "orange" }}
                  ></i>
                  Top-Up
                  <i
                    className="fas fa-circle ml-2 "
                    style={{ color: "gold" }}
                  ></i>
                  Kompensasi
                  <i
                    className="fas fa-circle ml-2 "
                    style={{ color: "cyan" }}
                  ></i>
                  Pelunasan
                  <i
                    className="fas fa-circle mr-0 "
                    style={{ color: "navy" }}
                  ></i>
                  Take Over
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row> */}

          <Row>
            <Col lg="6" sm="6">
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Informasi
              </h4>
              <Row>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 15,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Area Manager</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalAM.totalAm} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 15,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Supervisor</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalSpv.totalSpv} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 15,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Marketing</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalMarketing.totalSm} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                {auth.role === "admin" || auth.role === "super admin" ? (
                  <Col lg="4" sm="6">
                    <Card
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        borderRadius: 10,
                      }}
                    >
                      <Card.Header
                        className="d-none d-md-inline"
                        style={{
                          backgroundColor: "#6F88B5",
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      >
                        <Card.Title
                          as="h6"
                          style={{
                            textAlign: "center",
                            alignItems: "center",
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <b style={{ color: "white" }}>SM Sudah Login</b>
                        </Card.Title>
                      </Card.Header>
                      <Card.Body style={{ textAlign: "center" }}>
                        {totalMarketing.totalLogin} Karyawan
                      </Card.Body>
                    </Card>
                  </Col>
                ) : null}

                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Cabang</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalCbg.totalCabang} Cabang
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col lg="6" sm="6">
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Status
                {auth.role === "super admin" ||
                auth.role === "admin" ||
                auth.role === "client" ? (
                  <Button
                    className="btn-wd mr-1"
                    onClick={() => {
                      setModalExport(true);
                    }}
                    // variant="success"
                    style={{
                      marginLeft: 20,
                      backgroundColor: "#0DC3A4",
                      border: 0,
                      fontWeight: "bold",
                    }}
                  >
                    <i
                      className="fas fa-file-excel fa-lg"
                      style={{ marginRight: 10 }}
                    ></i>
                    Export Pengajuan
                  </Button>
                ) : null}
              </h4>
              <Row>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>menunggu</b>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Detail</Tooltip>}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() => setModalMenunggu(!modalMenunggu)}
                          >
                            <i
                              class="fas fa-external-link-alt"
                              style={{
                                fontSize: 15,
                                color: "white",
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            ></i>
                          </div>
                        </OverlayTrigger>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.menunggu
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Revisi</b>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Detail</Tooltip>}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() => setModalRevisi(!modalRevisi)}
                          >
                            <i
                              class="fas fa-external-link-alt"
                              style={{
                                fontSize: 15,
                                color: "white",
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            ></i>
                          </div>
                        </OverlayTrigger>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.revisi
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Ditolak</b>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Detail</Tooltip>}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() => setModalDitolak(!modalDitolak)}
                          >
                            <i
                              class="fas fa-external-link-alt"
                              style={{
                                fontSize: 15,
                                color: "white",
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            ></i>
                          </div>
                        </OverlayTrigger>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.ditolak
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>diajukan</b>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Detail</Tooltip>}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() => setModalDiajukan(!modalDiajukan)}
                          >
                            <i
                              class="fas fa-external-link-alt"
                              style={{
                                fontSize: 15,
                                color: "white",
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            ></i>
                          </div>
                        </OverlayTrigger>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.diajukan
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Diproses</b>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Detail</Tooltip>}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() => setModalDiproses(!modalDiproses)}
                          >
                            <i
                              class="fas fa-external-link-alt"
                              style={{
                                fontSize: 15,
                                color: "white",
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            ></i>
                          </div>
                        </OverlayTrigger>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.proses
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Disetujui</b>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Detail</Tooltip>}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() => setModalDisetujui(!modalDisetujui)}
                          >
                            <i
                              class="fas fa-external-link-alt"
                              style={{
                                fontSize: 15,
                                color: "white",
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            ></i>
                          </div>
                        </OverlayTrigger>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.disetujui
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6F88B5",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Diajukan (File Gagal)</b>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Detail</Tooltip>}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() =>
                              setModalDiajukanFileGagal(!modalDiajukanFileGagal)
                            }
                          >
                            <i
                              class="fas fa-external-link-alt"
                              style={{
                                fontSize: 15,
                                color: "white",
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            ></i>
                          </div>
                        </OverlayTrigger>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.diajukanGagalFile
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card style={{ border: 0 }}>
                <Card.Header>
                  <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                    Cabang
                    <hr />
                  </Card.Title>
                </Card.Header>
                <Row>
                  <Col md="12">
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#ABC68B",
                            width: 15,
                            height: 15,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Pencairan cabang</label>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#008170",
                            width: 15,
                            height: 15,
                            marginLeft: 20,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Target Cabang</label>
                      </div>
                      <VictoryChart
                        height={280}
                        width={850}
                        style={{ parent: { marginLeft: "1rem" } }}
                        domainPadding={{ x: 50 }}
                      >
                        <VictoryGroup
                          offset={13}
                          colorScale={["#ABC68B", "#008170"]}
                        >
                          <VictoryBar
                            data={chartData.series[0].map((yValue, index) => {
                              const yInBillion = yValue / 1000000000;
                              const decimalDigits = yInBillion
                                .toString()
                                .split(".")[1];
                              // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                              return {
                                x: chartData.labels[index],
                                y: yInBillion,
                                label: `${yInBillion}`,
                                // dy: dyValue,
                              };
                            })}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#ABC68B",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />

                          <VictoryBar
                            data={chartData.series[1].map((yValue, index) => {
                              const yInBillion = yValue / 1000000000;
                              const decimalDigits = yInBillion
                                .toString()
                                .split(".")[1];
                              // const dyValue =
                              //   decimalDigits && decimalDigits.length > 2
                              //     ? -5
                              //     : -10;

                              return {
                                x: chartData.labels[index],
                                y: yInBillion,
                                label: `${yInBillion}`,
                                // dy: dyValue,
                              };
                            })}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#537B25",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />
                        </VictoryGroup>
                        <VictoryAxis
                          label="Nama Cabang"
                          tickLabelComponent={
                            <VictoryLabel style={{ fontSize: "10px" }} />
                          }
                          style={{
                            axisLabel: {
                              padding: 30,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                          }}
                        />
                        <VictoryAxis
                          dependentAxis
                          tickCount={6}
                          label="Nominal Pencairan dalam Miliar"
                          style={{
                            axisLabel: {
                              padding: 42.5,
                              fontSize: "9px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                            tickLabels: {
                              fontSize: "10px",
                              // padding: 30,
                              // textAnchor: "start",
                            },
                          }}
                          domain={
                            chartData.series.some((data) =>
                              data.slice(0, 10).some((yValue) => yValue !== 0)
                            )
                              ? undefined
                              : { y: [0, 1] }
                          }
                        />
                      </VictoryChart>
                    </div>
                  </Col>
                  <Col md="12" style={{ marginTop: 30 }}>
                    <Card
                      style={{
                        border: 0,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        borderRadius: 10,
                        marginRight: 30,
                        marginLeft: 30,
                        // backgroundColor: "#FFF59D",
                        color: "black",
                        padding: 20,
                      }}
                    >
                      <div style={{ marginBottom: 20 }}>
                        <Row>
                          <Col>
                            <h4
                              className="title mt-2"
                              style={{
                                fontWeight: "bold",
                                marginLeft: 20,
                                textAlign: "start",
                              }}
                            >
                              Keterangan Nama Cabang
                            </h4>
                          </Col>
                          <Col style={{ textAlign: "end" }}>
                            <Button
                              className="mr-1"
                              // variant="primary"
                              style={{
                                marginBottom: 10,
                                fontSize: 16,
                                backgroundColor: "#516B7E",
                                border: 0,
                                paddingLeft: 10,
                                paddingRight: 10,
                                fontWeight: "bold",
                              }}
                              onClick={() => setModalDetail(!modalDetail)}
                            >
                              Detail Semua Cabang
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <div
                        style={{
                          marginLeft: 20,
                          flexWrap: "wrap",
                          display: "flex",
                        }}
                      >
                        {dataGrafikCabang.map((cabang, index) => {
                          return (
                            <div
                              style={{
                                borderWidth: 2,
                                width: 300,
                                marginTop: 10,
                              }}
                            >
                              <Row key={index}>
                                <Col sm="1">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",

                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",

                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}

                        {/* <Row>
                        <Col md="6">
                          {dataGrafikCabang.map((cabang, index) =>
                            index < 5 ? (
                              <Row key={index}>
                                <Col sm="1">
                                 
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                                  
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            ) : null
                          )}
                        </Col>

                        <Col md="6">
                          {dataGrafikCabang.map((cabang, index) =>
                            index >= 5 ? (
                              <Row key={index}>
                                <Col sm="1">
                                
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                        
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            ) : null
                          )}
                        </Col>
                      </Row> */}
                      </div>
                      {/* <div
                      style={{
                        textAlign: "end",
                      }}
                    >
                      <Button
                        className="btn-wd mr-1"
                        variant="primary"
                        style={{ marginTop: 15, marginBottom: 10 }}
                        onClick={() => setModalDetail(!modalDetail)}
                      >
                        Tampilkan Detail Semua Cabang
                      </Button>
                    </div> */}
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card style={{ border: 0 }}>
                <Card.Header>
                  <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                    <span> {auth.nama} </span>
                    <hr />
                  </Card.Title>
                  <Col md={"3"}>
                    <label style={{ color: "black", fontSize: 14 }}>
                      Pilih Tahun
                    </label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      defaultValue={{ label: yearNow, value: yearNow }}
                      onChange={(value) => {
                        setTahun(value.value);
                      }}
                      options={listYear}
                      // placeholder="Pilih Tahun"
                    />
                    {/* <Form.Control
                    onChange={(e) => {
                      setTahun(e.target.value);
                    }}
                    placeholder="Input Tahun"
                    type="month"
                  ></Form.Control> */}
                    {/* <p style={{ color: "gray", marginTop: 10 }}>
                    Tahun dan bulan saat ini :{" "}
                    {isDateValid
                      ? moment(tahun).format("MMMM YYYY")
                      : "Tidak ada data"}
                  </p> */}
                  </Col>
                </Card.Header>
                <Row>
                  <Col md="12">
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#D5BECD",
                            width: 15,
                            height: 15,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Pencairan User</label>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#734A65",
                            width: 15,
                            height: 15,
                            marginLeft: 20,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Target User</label>
                      </div>
                      <VictoryChart
                        height={250}
                        width={850}
                        style={{ parent: { marginLeft: "1rem" } }}
                        domainPadding={{ x: 50 }}
                      >
                        <VictoryGroup
                          offset={12.5}
                          colorScale={["#D5BECD", "#734A65"]}
                        >
                          <VictoryBar
                            data={chartDataUser.series[0].map(
                              (yValue, index) => {
                                const yInBillion = yValue / 1000000000;
                                const decimalDigits = yInBillion
                                  .toString()
                                  .split(".")[1];
                                // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                                return {
                                  x: chartDataUser.labels[index],
                                  y: yInBillion,
                                  label: `${yInBillion}`,
                                  // dy: dyValue,
                                };
                              }
                            )}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#D5BECD",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />

                          <VictoryBar
                            data={chartDataUser.series[1].map(
                              (yValue, index) => {
                                const yInBillion = yValue / 1000000000;
                                const decimalDigits = yInBillion
                                  .toString()
                                  .split(".")[1];
                                // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                                return {
                                  x: chartDataUser.labels[index],
                                  y: yInBillion,
                                  label: `${yInBillion}`,
                                  // dy: dyValue,
                                };
                              }
                            )}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                dy={-2}
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#734A65",
                                  padding: 5,
                                }}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />
                        </VictoryGroup>
                        <VictoryAxis
                          tickLabelComponent={
                            <VictoryLabel style={{ fontSize: "10px" }} />
                          }
                          label="Bulan"
                          style={{
                            axisLabel: {
                              padding: 30,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                          }}
                        />
                        <VictoryAxis
                          dependentAxis
                          label="Nominal Pencairan dalam Miliar"
                          style={{
                            axisLabel: {
                              padding: 42.5,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                            tickLabels: {
                              fontSize: "10px",
                            },
                          }}
                          domain={
                            chartDataUser.series.some((data) =>
                              data.some((yValue) => yValue !== 0)
                            )
                              ? undefined
                              : { y: [0, 1] }
                          }
                        />
                      </VictoryChart>
                    </div>
                  </Col>
                </Row>

                {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
                {/* </Card.Body> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  );
}

export default DashboardUtama;
