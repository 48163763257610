const initialState = {
  totalSupervisor: [],
  totalMarketing: [],
  totalAreaManager: [],
  totalCabang: [],
  totalAllPengajuan: [],
  totalPengajuan: null,
};

const dashboardUtamaReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALL_TOTAL_SUPERVISOR":
      return {
        ...state,
        totalSupervisor: data,
      };
    case "SET_ALL_TOTAL_AREAMANAGER":
      return {
        ...state,
        totalAreaManager: data,
      };
    case "SET_ALL_TOTAL_MARKETING":
      return {
        ...state,
        totalMarketing: data,
      };
    case "SET_ALL_TOTAL_CABANG":
      return {
        ...state,
        totalCabang: data,
      };
    case "SET_ALL_TOTAL_PENGAJUAN":
      return {
        ...state,
        totalPengajuan: data,
      };
    case "SET_AMBIL_PENGAJUAN":
      return {
        ...state,
        totalAllPengajuan: data,
      };
    default:
      return state;
  }
};
export default dashboardUtamaReducer;
