/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";

import LoginPage from "views/Pages/LoginPage.js";
import PrivacyPolicy from "views/PrivacyPolicy";
import TermsConditions from "views/TermsConditions";
import Maintenance from "views/Maintenance";
import SettingGedungPage from "views/Pages/PatroliKebersihan/SettingGedung";
import SettingShiftPage from "views/Pages/PatroliKebersihan/SettingShift";
import RekapMonitoringPage from "views/Pages/PatroliKebersihan/RekapLaporan";
import DashboardMonitoringPage from "views/Pages/PatroliKebersihan/DashboardMonitoring";
import DashboardUtama from "views/DashboardUtama";
import ListClient from "views/Pages/Client/ListClient";
import ListUser from "views/Pages/User/TabUsers/IndexUser";
import ListCabang from "views/Pages/Cabang/ListCabang";
// import TargetClient from "views/Pages/Target/TargetClient";
import Pengajuan from "views/Pages/Pengajuan/Pengajuan";
import Pencairan from "views/Pages/Pencairan/Pencairan";
import TargetKorwil from "views/Pages/Target/TargetKorwil/TargetKorwil";
import TargetAreaManager from "views/Pages/Target/TargetAreaManager/TargetAreaManager";
import TargetSupervisor from "views/Pages/Target/TargetSupervisor/TargetSupervisor";
import TargetMarketing from "views/Pages/Target/TargetMarketing/TargetMarketing";
import TargetCabang from "views/Pages/Target/TargetCabang/TargetCabang";
import UserProfile from "views/Pages/User/UserProfile";
import PenggajianUser from "views/Pages/PenggajianUser/indexPenggajianUser";
import ManagementFee from "views/Pages/ManagementFee/ManagementFee";
import ManagementInsentive from "views/Pages/ManagementFee/ManagementInsentive";

var routes = [
  // {
  //   path: '/dashboard',
  //   layout: '/admin',
  //   name: 'Dashboard',
  //   icon: 'nc-icon nc-chart-pie-35',
  //   component: Dashboard,
  // },
  {
    show: true,
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "fas fa-home",
    component: DashboardUtama,
  },
  {
    show: true,
    collapse: true,
    path: "/client",
    name: "Client",
    state: "openClient",
    icon: "fas fa-landmark",
    views: [
      {
        show: true,
        path: "/listClient",
        layout: "/admin",
        name: <b>List Client</b>,
        mini: <b>LC</b>,
        component: ListClient,
      },
      // {
      //   path: '/targetClient',
      //   layout: '/admin',
      //   name: 'Target Client',
      //   component: TargetClient,
      // },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/user",
    name: "User",
    state: "openUser",
    icon: "fas fa-users",
    views: [
      {
        show: true,
        path: "/listUser",
        layout: "/admin",
        name: <b>List User</b>,
        mini: <b>LU</b>,
        component: ListUser,
      },
      // {
      //   path: "/targetUser",
      //   layout: "/admin",
      //   name: "Target User",
      //   component: TargetUser,
      // },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/cabang",
    name: "Cabang",
    state: "openCabang",
    icon: "fas fa-map-pin",
    views: [
      {
        show: true,
        path: "/ListCabang",
        layout: "/admin",
        name: <b>List Cabang</b>,
        mini: <b>LC</b>,
        component: ListCabang,
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/target",
    name: "Target",
    state: "openTarget",
    icon: "fas fa-chart-bar",
    views: [
      {
        show: true,
        path: "/TargetKorwil",
        layout: "/admin",
        name: <b>Target Korwil</b>,
        mini: <b>TK</b>,
        component: TargetKorwil,
      },
      {
        show: true,
        path: "/TargetAreaManager",
        layout: "/admin",
        name: <b>Target Area Manager</b>,
        mini: <b>TAM</b>,
        component: TargetAreaManager,
      },
      {
        show: true,
        path: "/TargetSupervisor",
        layout: "/admin",
        name: <b>Target Supervisor</b>,
        mini: <b>TS</b>,
        component: TargetSupervisor,
      },
      {
        show: true,
        path: "/TargetMarketing",
        layout: "/admin",
        name: <b>Target Marketing</b>,
        mini: <b>TM</b>,
        component: TargetMarketing,
      },
      {
        show: true,
        path: "/TargetCabang",
        layout: "/admin",
        name: <b>Target Cabang</b>,
        mini: <b>TC</b>,
        component: TargetCabang,
      },
    ],
  },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/pengajuan",
  //   name: "Pengajuan",
  //   state: "openPengajuan",
  //   icon: "nc-icon nc-single-copy-04 icon-bold",
  //   views: [
  //     {
  //       show: true,
  //       path: "/pengajuan",
  //       layout: "/admin",
  //       name: "Pengajuan",
  //       component: Pengajuan,
  //     },
  //   ],
  // },

  {
    show: true,
    path: "/pengajuan",
    layout: "/admin",
    name: "Pengajuan",
    icon: "far fa-copy",
    component: Pengajuan,
  },
  {
    show: true,
    path: "/pencairan",
    layout: "/admin",
    name: "Pencairan",
    icon: "fas fa-money-bill-alt",
    component: Pencairan,
  },
  {
    show: false,
    path: "/userprofile",
    layout: "/admin",
    name: "My Profile",
    icon: "far fa-user",
    component: UserProfile,
  },
  {
    show: true,
    collapse: true,
    path: "/fee",
    name: "Fee",
    state: "openManagementFee",
    icon: "fas fa-hand-holding-usd",
    views: [
      {
        show: true,
        path: "/penggajian",
        layout: "/admin",
        name: <b>Insentive</b>,
        mini: <b>Ins</b>,
        component: PenggajianUser,
      },
      {
        show: true,
        path: "/managementfee",
        layout: "/admin",
        name: <b>% Fee Management</b>,
        mini: <b>FM</b>,
        component: ManagementFee,
      },
      {
        show: true,
        path: "/managementinsentive",
        layout: "/admin",
        name: <b>% Fee insentive</b>,
        mini: <b>FI</b>,
        component: ManagementInsentive,
      },
    ],
  },
  //Template Awal
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       show: true,
  //       path: "/buttons",
  //       layout: "/admin",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       show: true,
  //       path: "/grid-system",
  //       layout: "/admin",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       show: true,
  //       path: "/panels",
  //       layout: "/admin",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       show: true,
  //       path: "/sweet-alert",
  //       layout: "/admin",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       show: true,
  //       path: "/notifications",
  //       layout: "/admin",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  //     {
  //       show: true,
  //       path: "/icons",
  //       layout: "/admin",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //     },
  //     {
  //       show: true,
  //       path: "/typography",
  //       layout: "/admin",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-forms",
  //       layout: "/admin",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //     },
  //     {
  //       show: true,
  //       path: "/extended-forms",
  //       layout: "/admin",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //     },
  //     {
  //       show: true,
  //       path: "/validation-forms",
  //       layout: "/admin",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //     },
  //     {
  //       show: true,
  //       path: "/wizard",
  //       layout: "/admin",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-tables",
  //       layout: "/admin",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //     },
  //     {
  //       show: true,
  //       path: "/extended-tables",
  //       layout: "/admin",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //     },
  //     {
  //       show: true,
  //       path: "/react-table",
  //       layout: "/admin",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       layout: "/admin",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       layout: "/admin",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //     },
  //     {
  //       path: "/vector-maps",
  //       layout: "/admin",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //     },
  //   ],
  // },
  // {
  //   path: "/charts",
  //   layout: "/admin",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  // },
  // {
  //   show: true,
  //   path: "/calendar",
  //   layout: "/admin",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar,
  // },
  //Template Akhir
  {
    show: false,
    path: "/login-page",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: LoginPage,
  },
  {
    show: false,
    path: "/privacy",
    layout: "/auth",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    show: false,
    path: "/termsandconditions",
    layout: "/auth",
    name: "Terms & Conditions",
    component: TermsConditions,
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    show: false,
    path: "/pemeliharaan",
    layout: "/auth",
    name: "Maintenance",
    component: Maintenance,
  },
  // {
  //   show: false,
  //   collapse: true,
  //   path: "/pages",
  //   name: "Pages",
  //   state: "openPages",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [
  //     // {
  //     //   path: '/user-page',
  //     //   layout: '/admin',
  //     //   name: 'User Page',
  //     //   mini: 'UP',
  //     //   component: UserPage,
  //     // },
  //     {
  //       show: false,
  //       path: "/login-page",
  //       layout: "/auth",
  //       name: "Login Page",
  //       mini: "LP",
  //       component: LoginPage,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: '/monitoringkebersihan',
  //   name: 'Monitoring',
  //   state: 'openKebersihan',
  //   icon: 'nc-icon nc-puzzle-10',
  //   views: [
  //     {
  //       path: '/dashboardmonitoring',
  //       layout: '/admin',
  //       name: 'Dashboard',
  //       mini: 'D',
  //       component: DashboardMonitoringPage,
  //     },
  //     {
  //       path: '/rekapmonitoring',
  //       layout: '/admin',
  //       name: 'Rekap Monitoring',
  //       mini: 'RM',
  //       component: RekapMonitoringPage,
  //     },
  //     {
  //       path: '/settingarea',
  //       layout: '/admin',
  //       name: 'Setting Area',
  //       mini: 'SA',
  //       component: SettingGedungPage,
  //     },
  //     {
  //       path: '/settingshift',
  //       layout: '/admin',
  //       name: 'Setting Shift',
  //       mini: 'SS',
  //       component: SettingShiftPage,
  //     },
  //   ],
  // },
];
export default routes;
