import React from "react";
import { Button, Card, Form, Col, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";

function Step4() {
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = pengajuan.detailPengajuan;
  // console.log(valDetail, "LLLLLLLLLL");

  return (
    <Col md="12" style={{ marginTop: 20 }}>
      <Card.Header>
        <Card.Title
          as="h4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          infomrasi pinjaman
          <hr></hr>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card style={{ border: 0 }}>
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            <div className="row">
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tujuan penggunaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.tujuanPenggunaan === "3"
                      ? "Konsumtif"
                      : "Produktif"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  Jenis Kredit
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.jenisKredit === "1"
                      ? "Multiguna Utama"
                      : valDetail.stepEmpat.jenisKredit === "2"
                      ? "Multiguna Pensiunan"
                      : valDetail.stepEmpat.jenisKredit === "3"
                      ? "Multiguna Kretap"
                      : valDetail.stepEmpat.jenisKredit === "4"
                      ? "Multiguna Terusan"
                      : "Multiguna Plus"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tenor
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.tenor}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nominal permohonan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {`Rp ${valDetail.stepEmpat.nominalPermohonan.toLocaleString(
                      "id-ID"
                    )}`}
                  </Label>
                </li>
              </div>
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nip
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.nip}
                  </Label>
                </li>
              </div> */}
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  suku bunga
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.sukuBunga}%
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  detail tujuan penggunaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.detailTujuanPenggunaan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  status pinjaman
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.statusPinjaman === 1
                      ? "New"
                      : valDetail.stepEmpat.statusPinjaman === 2
                      ? "Top Up"
                      : valDetail.stepEmpat.statusPinjaman === 3
                      ? "Kompensasi"
                      : valDetail.stepEmpat.statusPinjaman === 4
                      ? "Take Over"
                      : "Pelunasan Di Pengajuan"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  cabang pencairan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.idCabangPencairan ===
                    valDetail.lokasiPencairan.namaCabang
                      ? valDetail.lokasiPencairan.namaCabang
                      : valDetail.lokasiPencairan.namaCabang}
                  </Label>
                </li>
              </div>
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  pembayaran gaji melalui
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.pembayaranGajiMelalui === "1"
                      ? "PT BPD KALTIMTARA"
                      : valDetail.stepEmpat.pembayaranGajiMelalui === "2"
                      ? "Bendahara"
                      : valDetail.stepEmpat.pembayaranGajiMelalui === "3"
                      ? "Koperasi"
                      : valDetail.stepEmpat.pembayaranGajiMelalui === "4"
                      ? "Bank Lain"
                      : "Lainnya"}
                  </Label>
                </li>
              </div> */}
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat cabang
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.lokasiPencairan.alamat}
                  </Label>
                </li>
              </div> */}
            </div>
          </div>
        </Card>
      </Card.Body>
    </Col>
  );
}
export default Step4;
