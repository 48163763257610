import React from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal,
  Tab,
} from "react-bootstrap";
import { tambahClient, getAllClient } from "stores";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

function ListClient() {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.clientReducer);
  const auth = useSelector((state) => state.authReducer);
  const [modalClient, setModalClient] = React.useState(false);
  const [namaClient, setNamaClient] = React.useState("");
  const [listClient, setListClient] = React.useState([]);

  const submitClient = () => {
    // if (namaClient === "") {
    // } else {
    tambahClient({
      namaClient: namaClient,
    })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          setNamaClient("");
          setModalClient(false);
          getAllClient(dispatch);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("ERRORRRR", error);
      });
    // }
  };

  React.useEffect(() => {
    let tmp =
      client.listClient &&
      client.listClient.map((val) => {
        return {
          ...val,
          action: (
            <>
              {" "}
              <Button
                onClick={() => {}}
                variant="warning"
                size="sm"
                className="text-warning btn-link edit"
              >
                <i className="fa fa-edit" />
              </Button>
            </>
          ),
        };
      });
    setListClient(tmp);
  }, [client.listClient]);

  const customStyles = {
    rows: {
      style: {
        height: 80,
        fontSize: 20,
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 18,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colClient = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => index + 1,
    },
    {
      name: "Nama Client",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaClient,
    },

    // {
    //   name: "Aksi",
    //   minWidth: "200px",
    //   center: true,
    //   selector: (row) => row.action,
    // },
  ];

  React.useEffect(() => {
    getAllClient(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{ fontWeight: "bold", marginBottom: 10 }}
                >
                  List Client
                </Card.Title>
              </Card.Header>
              {auth.role === "super admin" ? (
                <Button
                  className="btn-wd mr-1"
                  // variant="primary"
                  style={{
                    marginTop: 10,
                    marginLeft: 15,
                    width: 100,
                    marginBottom: 20,
                    backgroundColor: "#425A6B",
                    border: 0,
                  }}
                  onClick={() => setModalClient(!modalClient)}
                >
                  Tambah Client
                </Button>
              ) : null}

              <Tab.Content>
                <Card.Body>
                  <DataTable
                    columns={colClient}
                    data={listClient}
                    customStyles={customStyles}
                    pagination
                  />
                </Card.Body>
              </Tab.Content>

              <Modal
                size="lg"
                show={modalClient}
                onHide={() => setModalClient(!modalClient)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton></Modal.Header>
                <Col md="12" style={{ marginTop: 20 }}>
                  <Card className="stacked-form">
                    <Card.Header>
                      <Card.Title
                        as="h4"
                        style={{ color: "black", fontWeight: "bold" }}
                      >
                        Tambah Client Baru
                        <hr></hr>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form action="#" method="#">
                        <Form.Group>
                          <label>Nama Client</label>
                          <Form.Control
                            onChange={(e) => {
                              setNamaClient(e.target.value);
                            }}
                            placeholder="Nama Client"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Form>
                    </Card.Body>

                    <Card.Footer>
                      <Button
                        className="btn-fill"
                        type="submit"
                        // variant="info"
                        style={{ backgroundColor: "#00BFFF", border: 0 }}
                        onClick={submitClient}
                      >
                        Submit
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListClient;
