import React from "react";

const PrivacyPolicy = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  };

  const contentStyle = {
    textAlign: "center",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  };

  const headerStyle = {
    fontSize: "36px",
    marginBottom: "20px",
    color: "#333",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
    fontWeight: "bold",
  };

  const paragraphStyle = {
    fontSize: "18px",
    color: "#666",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={headerStyle}>Server Sedang Dalam Pemeliharaan</h1>
        <p style={paragraphStyle}>
          Mohon maaf atas ketidaknyamanan ini. Kami sedang melakukan
          pemeliharaan untuk meningkatkan layanan kami. Silakan coba lagi nanti.
        </p>
        <img
          alt="..."
          src={require("assets/img/Maintenance.jpg").default}
          style={{ maxWidth: "50%", marginTop: "20px" }}
        />{" "}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
