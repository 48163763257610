import React from "react";
import { Button, Card, Form, Col, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import Moment from "moment";
import "moment/locale/id";

function Step2() {
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = pengajuan.detailPengajuan;
  Moment.locale("id");
  const tglLahirPasangan = Moment(valDetail.stepDua.tglLahirPasangan).format(
    "DD MMMM YYYY"
  );

  return (
    <Col md="12" style={{ marginTop: 20 }}>
      <Card.Header>
        <Card.Title
          as="h4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          informasi pasangan
          <hr></hr>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card style={{ border: 0 }}>
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            <div className="row">
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nama pasangan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.namaPasangan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  no ktp pasangan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.noKtpPasangan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  no. handphone
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.noHpPasangan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tanggal lahir pasangan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {tglLahirPasangan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat pasangan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.alamatPasangan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tempat lahir pasangan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.tempatLahirPasangan}
                  </Label>
                </li>
              </div>
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat pasangan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.alamatPasangan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nip pasangan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.nipPasangan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  npwp pasangan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.npwpPasangan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  sedang bekerja
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.sedangBekerja === "true"
                      ? "Ya"
                      : "Tidak"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nama pekerjaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.namaPekerjaan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nama instansi
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.namaInstansi}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat pekerjaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.alamatPekerjaan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  telepon kantor
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.telpKantor}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat kantor
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.alamatKantor}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  pangkat golongan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.pangkatGolongan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  jabatan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.jabatan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  awal bekerja
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepDua.awalBekerja}
                  </Label>
                </li>
              </div> */}
            </div>
          </div>
        </Card>
      </Card.Body>
    </Col>
  );
}
export default Step2;
