import React from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import {
  Button,
  Card,
  Form,
  Col,
  Modal,
  Tab,
  Nav,
  Row,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { createPersenMf } from "stores/ManagementFee/function";
import { getAllPersenMf } from "stores/ManagementFee/function";
import { Dna } from "react-loader-spinner";
import { Label } from "reactstrap";
import { editPersenMf } from "stores";
import { getListManagementFee } from "stores";

const ManagementFee = () => {
  const dispatch = useDispatch();
  const mfReducer = useSelector((state) => state.managementFeeReducer);
  const auth = useSelector((state) => state.authReducer);
  const [modalManagementFee, setModalManagementFee] = React.useState(false);
  const [modalEditManagementFee, setModalEditManagementFee] =
    React.useState(false);
  const [tanggal, setTanggal] = React.useState("");
  const [idPersen, setIdPersen] = React.useState("");
  const [editTanggal, setEditTanggal] = React.useState("");
  // const [val, setVal] = React.useState(null);
  const [formData, setFormData] = React.useState({
    new: 0,
    topup: 0,
    takeover: 0,
    kompensasi: 0,
    pengajuan: 0,
  });

  const [formEditData, setFormEditData] = React.useState({
    new: 0,
    topup: 0,
    takeover: 0,
    kompensasi: 0,
    pengajuan: 0,
  });
  const [listPersenMf, setListPersenMf] = React.useState([]);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
    setPage(0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const validValue = value.toString().replace(/[^0-9.,]/g, "");
    setFormData({
      ...formData,
      [name]: validValue,
    });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    const validValue = value.toString().replace(/[^0-9.,]/g, "");
    setFormEditData({
      ...formEditData,
      [name]: validValue,
    });
  };

  const handleModalClose = () => {
    setModalManagementFee(false);
    setFormData({
      new: 0,
      topup: 0,
      takeover: 0,
      kompensasi: 0,
      pengajuan: 0,
    });
    setModalEditManagementFee(false);
  };

  const formatDecimal = (value) => {
    return value.toString().replace(",", ".");
  };

  const submitCreateMf = () => {
    createPersenMf({
      baru: formatDecimal(formData.new),
      takeOver: formatDecimal(formData.takeover),
      topUp: formatDecimal(formData.topup),
      kompensasi: formatDecimal(formData.kompensasi),
      pelunasan: formatDecimal(formData.pengajuan),
      tglPenggunaan: tanggal,
    })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            title: response.data.message,
            timer: 1000,
          });
          handleModalClose();
          getAllPersenMf(dispatch, {
            page: page + 1,
            limit: totalPerHalaman,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("ERRORRRR", error);
      });
  };

  const submitEditMf = () => {
    editPersenMf({
      idPersen: idPersen,
      baru: formatDecimal(formEditData.new),
      takeOver: formatDecimal(formEditData.takeover),
      topUp: formatDecimal(formEditData.topup),
      kompensasi: formatDecimal(formEditData.kompensasi),
      pelunasan: formatDecimal(formEditData.pengajuan),
      tglPenggunaan: editTanggal,
    })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            title: response.data.message,
            timer: 1000,
          });
          handleModalClose();
          getAllPersenMf(dispatch, {
            page: page + 1,
            limit: totalPerHalaman,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("ERRORRRR", error);
      });
  };

  const handleEditClick = (val) => {
    setFormEditData({
      new: val.baru,
      topup: val.topUp,
      takeover: val.takeOver,
      kompensasi: val.kompensasi,
      pengajuan: val.pelunasan,
    });
    setIdPersen(val._id);
    setEditTanggal(val.tglPenggunaan);
    setModalEditManagementFee(true);
  };

  React.useEffect(() => {
    let tmp =
      mfReducer.getDataPersenMf &&
      mfReducer.getDataPersenMf.map((val, index) => {
        return {
          ...val,
          action: (
            <div className="actions-right">
              {/* <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-0"
                  style={{
                    marginTop: 5,
                    padding: "7px 16px",
                    fontSize: "18px",
                    backgroundColor: "#2C6C90",
                    border: 0,
                  }}
                  // onClick={() => {
                  //   // console.log(val, "KKKK");
                  //   handleDetailClick(val);
                  // }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger> */}
              {auth.role === "super admin" || auth.nopeg === "02240105" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Edit</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    style={{
                      marginTop: 5,
                      padding: "7px 16px",
                      fontSize: "18px",
                      backgroundColor: "#F75D59",
                      border: 0,
                    }}
                    onClick={() => {
                      handleEditClick(val);
                    }}
                  >
                    <i class="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
              ) : null}
            </div>
          ),
        };
      });
    setListPersenMf(tmp);
  }, [mfReducer.getDataPersenMf, auth]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const renderForm = (field) => (
    <Form.Group as={Row} controlId={`form${field}`}>
      <Col sm={8}>
        <Form.Control
          type="text"
          name={field}
          value={formData[field]}
          onChange={handleInputChange}
          placeholder={`Input ${
            field.charAt(0).toUpperCase() + field.slice(1)
          }`}
        />
      </Col>
    </Form.Group>
  );

  const renderEditForm = (field) => (
    <Form.Group as={Row} controlId={`form${field}`}>
      <Col sm={8}>
        <Form.Control
          type="text"
          name={field}
          value={formEditData[field]}
          onChange={handleEditInputChange}
          placeholder={`Input ${
            field.charAt(0).toUpperCase() + field.slice(1)
          }`}
        />
      </Col>
    </Form.Group>
  );

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
  };

  const colPencairanAll = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + mfReducer.getPaginationPersenMf.pagingCounter,
    },
    {
      name: "Tgl Penggunaan",
      minWidth: "290px",
      center: true,
      selector: (row) => moment(row.tglPenggunaan).format("DD MMMM YYYY"),
    },
    {
      name: "New",
      minWidth: "290px",
      center: true,
      selector: (row) => `${row.baru} %`,
    },
    {
      name: "Top Up",
      minWidth: "290px",
      center: true,
      selector: (row) => `${row.topUp} %`,
    },
    {
      name: "Kompensasi",
      minWidth: "290px",
      center: true,
      selector: (row) => `${row.kompensasi} %`,
    },
    {
      name: "Pelunasan",
      minWidth: "290px",
      center: true,
      selector: (row) => `${row.pelunasan} %`,
    },
    {
      name: "Take Over",
      minWidth: "290px",
      center: true,
      selector: (row) => `${row.takeOver} %`,
    },
    {
      name: "Aksi",
      minWidth: "250px",
      omit:
        auth.role === "super admin" || auth.nopeg === "02240105" ? false : true,
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    getAllPersenMf(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);

  return (
    <>
      <style type="text/css">
        {`
          /* Hide the spinner for number inputs in Chrome, Safari, Edge, and Opera */
          .no-spinner::-webkit-outer-spin-button,
          .no-spinner::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          
          /* Hide the spinner for number inputs in Firefox */
          .no-spinner[type="number"] {
            -moz-appearance: textfield;
          }
        `}
      </style>

      <Modal
        size="lg"
        show={modalManagementFee}
        onHide={handleModalClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Card className="stacked-form">
          <Modal.Header closeButton></Modal.Header>
          <Card.Header>
            <Card.Title
              as="h4"
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 20,
              }}
            >
              Form Management Fee
            </Card.Title>
          </Card.Header>
        </Card>

        <Modal.Body>
          <Tab.Container defaultActiveKey="new">
            <Col sm={6}>
              <Form>
                <Form.Group as={Row} controlId="formTanggal">
                  <li>
                    <Form.Label
                      sm={4}
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      Pilih Bulan
                    </Form.Label>
                  </li>
                  <Form.Control
                    onChange={(e) => {
                      setTanggal(e.target.value + "-01");
                    }}
                    placeholder="Input Tanggal"
                    type="month"
                  />
                </Form.Group>
              </Form>
            </Col>
            <li>
              <Label style={{ color: "black", fontWeight: "bold" }}>
                Input Presentase
              </Label>
            </li>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="new">New</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="topup">Top Up</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="takeover">Take Over</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="kompensasi">Kompensasi</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pengajuan">Pengajuan Di pelunasan</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content style={{ marginTop: 20 }}>
              <Tab.Pane eventKey="new">{renderForm("new")}</Tab.Pane>
              <Tab.Pane eventKey="topup">{renderForm("topup")}</Tab.Pane>
              <Tab.Pane eventKey="takeover">{renderForm("takeover")}</Tab.Pane>
              <Tab.Pane eventKey="kompensasi">
                {renderForm("kompensasi")}
              </Tab.Pane>
              <Tab.Pane eventKey="pengajuan">
                {renderForm("pengajuan")}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill"
            type="submit"
            style={{
              backgroundColor: "#425A6B",
              border: 0,
              fontWeight: "bold",
            }}
            onClick={submitCreateMf}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={modalEditManagementFee}
        onHide={handleModalClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Card className="stacked-form">
          <Modal.Header closeButton></Modal.Header>
          <Card.Header>
            <Card.Title
              as="h4"
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 20,
              }}
            >
              Form Edit Management Fee
            </Card.Title>
          </Card.Header>
        </Card>

        <Modal.Body>
          <Tab.Container defaultActiveKey="new">
            <Col sm={6}>
              <Form>
                <Form.Group as={Row} controlId="formTanggal">
                  <li>
                    <Form.Label
                      sm={4}
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      Pilih Bulan
                    </Form.Label>
                  </li>
                  <Form.Control
                    type="month"
                    onChange={(e) => {
                      setEditTanggal(e.target.value + "-01");
                    }}
                    value={editTanggal.slice(0, 7)}
                    placeholder="Input Tanggal"
                  />
                </Form.Group>
              </Form>
            </Col>
            <li>
              <Label style={{ color: "black", fontWeight: "bold" }}>
                Input Presentase
              </Label>
            </li>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="new">New</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="topup">Top Up</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="takeover">Take Over</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="kompensasi">Kompensasi</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pengajuan">Pengajuan Di pelunasan</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content style={{ marginTop: 20 }}>
              <Tab.Pane eventKey="new">{renderEditForm("new")}</Tab.Pane>
              <Tab.Pane eventKey="topup">{renderEditForm("topup")}</Tab.Pane>
              <Tab.Pane eventKey="takeover">
                {renderEditForm("takeover")}
              </Tab.Pane>
              <Tab.Pane eventKey="kompensasi">
                {renderEditForm("kompensasi")}
              </Tab.Pane>
              <Tab.Pane eventKey="pengajuan">
                {renderEditForm("pengajuan")}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill"
            type="submit"
            style={{
              backgroundColor: "#425A6B",
              border: 0,
              fontWeight: "bold",
            }}
            onClick={submitEditMf}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Fee Management
                  <hr></hr>
                </Card.Title>
              </Card.Header>
              <Tab.Content>
                <Card style={{ border: 0 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col
                      md={"4"}
                      style={{
                        marginTop: 20,
                        marginRight: 25,
                      }}
                    >
                      {auth.role === "super admin" ||
                      auth.nopeg === "02240105" ? (
                        <Button
                          className="btn-wd ml-3"
                          // variant="primary"
                          style={{
                            marginTop: 10,
                            marginBottom: 20,
                            backgroundColor: "#425A6B",
                            border: 0,
                            fontWeight: "bold",
                          }}
                          onClick={() =>
                            setModalManagementFee(!modalManagementFee)
                          }
                        >
                          Tambah % Fee Management
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                  <Card.Body>
                    {loading ? (
                      <div
                        className="loader-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "30vh",
                        }}
                      >
                        <Dna
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="dna-loading"
                          wrapperStyle={{}}
                          wrapperClass="dna-wrapper"
                        />
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <>
                        <div>
                          <DataTable
                            columns={colPencairanAll}
                            data={listPersenMf}
                            customStyles={customStyles}
                            paginationPerPage={totalPerHalaman}
                            // progressPending={pending}
                          />
                          <div style={{ marginTop: 35 }}>
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Col>
                                <Row style={{ alignItems: "center" }}>
                                  <div
                                    style={{
                                      paddingRight: 0,
                                      marginLeft: 20,
                                      marginRight: 10,
                                    }}
                                  >
                                    <label style={{ fontWeight: "bold" }}>
                                      Rows per page
                                    </label>
                                  </div>
                                  <Col sm="3" style={{ paddingLeft: 0 }}>
                                    <Select
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      name="Pilih Client"
                                      value={options.find(
                                        (option) =>
                                          option.value === totalPerHalaman
                                      )}
                                      onChange={handlePageSizeChange}
                                      options={options}
                                      placeholder="Rows per page"
                                    />
                                  </Col>
                                </Row>
                              </Col>

                              <Col>
                                <ReactPaginate
                                  forcePage={page}
                                  containerClassName="paginations justify-content-end"
                                  previousClassName="pages-items-containers"
                                  previousLinkClassName="pages-links-labels"
                                  nextClassName="pages-items-containers"
                                  nextLinkClassName="pages-links-labels"
                                  breakClassName="pages-items-containers"
                                  pageCount={
                                    mfReducer.getPaginationPersenMf.totalPages
                                  }
                                  pageRangeDisplayed={3}
                                  marginPagesDisplayed={1}
                                  pageLinkClassName="pages-links-labels"
                                  pageClassName="pages-items-containers"
                                  activeClassName="activees"
                                  activeLinkClassName="activess"
                                  nextLabel=" > "
                                  previousLabel=" < "
                                  initialPage={page}
                                  disableInitialCallback={true}
                                  onPageChange={(val) => {
                                    setPage(val.selected);
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Tab.Content>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ManagementFee;
