import { baseAxios } from "../index";

export async function getAllUser(dispatch, data, history) {
  try {
    const response = await baseAxios("webUser/getAllUser", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLUSER", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahSuperAdmin(data) {
  try {
    const response = await baseAxios.post("webUser/tambahSuperAdmin", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function tambahUser(data) {
  try {
    const response = await baseAxios.post("webUser/tambahUser", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function editStatusUser(data) {
  try {
    const response = await baseAxios.patch("webUser/ubahStatusUser", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    throw err.response.data;
  }
}

export async function addProfile(data) {
  try {
    const response = await baseAxios.post("webLogin/addProfile", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

// export async function addProfile(data) {
//   try {
//     const response = await baseAxios.post("webLogin/addProfile", data, {
//       headers: {
//         token: localStorage.getItem("token"),
//       },
//     });
//     return response;
//   } catch (err) {
//     console.log("error upload", err);
//     return err.response;
//   }
// }

export async function getAllProfile(dispatch, data, history) {
  try {
    const response = await baseAxios.post("webLogin/getProfile", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // dispatch({ type: "SET_ALL_PROFILE", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

// export async function updateFotoProfile(data) {
//   try {
//     const response = await baseAxios.patch("webLogin/updateFotoProfile", data, {
//       headers: { token: localStorage.getItem("token") },
//     });
//     console.log(response);
//     return response.data;
//   } catch (err) {
//     throw err.response.data;
//   }
// }

export async function updateFotoProfile(data) {
  try {
    const response = await baseAxios.patch("webLogin/updateFotoProfile", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
