import { baseAxios } from "../index";

export async function createPersenMf(data) {
  try {
    const response = await baseAxios.post("webPengajuan/createPersenMf", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPersenMf(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPersenMf?page=${data.page}&limit=${data.limit}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALL_PERSENMF", data: response.data.data });
    dispatch({ type: "SET_ALL_PAGINATION_PERSENMF", data: response.data });
    // console.log(response, "OOO");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function editPersenMf(data) {
  try {
    const response = await baseAxios.patch(
      "webPengajuan/updatePersenMf",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // console.log(response, "OOO");
    return response;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getListManagementFee(dispatch, data, history) {
  // console.log(data, "DATAA");
  try {
    const response = await baseAxios.get(
      `webPengajuan/getListManagamentFee?filterTgl=${data.filterTgl}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALL_GET_MANAGEMENT_FEE", data: response.data.data });
    // console.log(response, "OOO");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function createPersenInsentive(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/createPersenIns",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPersenInsentive(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPersenIns?page=${data.page}&limit=${data.limit}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALL_PERSEN_INSENTIVE", data: response.data.data });
    dispatch({
      type: "SET_ALL_PAGINATION_PERSEN_INSENTIVE",
      data: response.data,
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function editPersenIns(data) {
  try {
    const response = await baseAxios.patch(
      "webPengajuan/updatePersenIns",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    throw err.response.data;
  }
}
