import React from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import {
  Button,
  Card,
  Form,
  Col,
  Modal,
  Row,
  Tab,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { Dna } from "react-loader-spinner";
import { pencairanAllAM } from "stores/Pengajuan/function";
import DetailAreaManager from "./DetailAreaManager";
// import newAM from "./DetailPengajuanAM/newAM";
import sorryImage from "assets/img/sorry.jpg";

function PenggajianAreaManager() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const dataInsentive = useSelector((state) => state.pengajuanReducer);
  const totalIntensif = useSelector(
    (state) => state.pengajuanReducer.pencairanALLData
  );
  // const [dariTgl, setDariTgl] = React.useState(
  //   new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
  //     0,
  //     0,
  //     0,
  //     0
  //   )
  // );
  // const [smpTgl, setSmpTgl] = React.useState(new Date());
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [smpTgl, setSmpTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(
      23,
      59,
      59,
      999
    )
  );
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [listPencairanAll, setListPencairanAll] = React.useState([]);
  const [listPengajuanKey, setListPengajuanKey] = React.useState([]);
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
    setPage(0);
  };
  const [semuaVal, setSemuaVal] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const handleDariTglChange = (selectedDate) => {
    const startOfMonth = moment(selectedDate).startOf("month").toDate();
    const endOfMonth = moment(selectedDate).endOf("month").toDate();
    setDariTgl(startOfMonth.setHours(0, 0, 0, 0)); // Mengatur dariTgl ke tgl 1 dari bulan yang dipilih
    setSmpTgl(endOfMonth.setHours(23, 59, 59, 999)); // Mengatur smpTgl ke akhir hari dari bulan yang dipilih
  };

  const [statusCode, setStatusCode] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleFilterChange = () => {
    setLoading(true);
    pencairanAllAM(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setLoading(false);
        // setPending(false);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleDetailClick = (val) => {
    setSemuaVal(val);
    dispatch({ type: "SET_DETAIL_INSENTIVE_AM", data: false });
    // dispatch({ type: "SET_DATA_DETAIL_INSENTIVE", data: val });
  };

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  React.useEffect(() => {
    let tmp =
      dataInsentive.pencairanALLDataAM &&
      dataInsentive.pencairanALLDataAM.map((val, index) => {
        const pengajuanKey = val.pengajuan || [];
        let tmp2 = {
          New: {
            length: pengajuanKey[1]?.pengajuan?.length || 0,
            insentive: pengajuanKey[1]?.insentive || 0,
            totalNominal: pengajuanKey[1]?.totalNominal || 0,
          },
          TopUp: {
            length: pengajuanKey[2]?.pengajuan?.length || 0,
            insentive: pengajuanKey[2]?.insentive || 0,
            totalNominal: pengajuanKey[2]?.totalNominal || 0,
          },
          Kompensasi: {
            length: pengajuanKey[3]?.pengajuan?.length || 0,
            insentive: pengajuanKey[3]?.insentive || 0,
            totalNominal: pengajuanKey[3]?.totalNominal || 0,
          },
          TakeOver: {
            length: pengajuanKey[4]?.pengajuan?.length || 0,
            insentive: pengajuanKey[4]?.insentive || 0,
            totalNominal: pengajuanKey[4]?.totalNominal || 0,
          },
          PelunasanDiPengajuan: {
            length: pengajuanKey[5]?.pengajuan?.length || 0,
            insentive: pengajuanKey[5]?.insentive || 0,
            totalNominal: pengajuanKey[5]?.totalNominal || 0,
          },
        };

        let totalKeseluruhan =
          tmp2.New.totalNominal +
          tmp2.TopUp.totalNominal +
          tmp2.Kompensasi.totalNominal +
          tmp2.TakeOver.totalNominal +
          tmp2.PelunasanDiPengajuan.totalNominal;

        return {
          ...val,
          ...tmp2,
          totalKeseluruhan,
          action: (
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-1"
                  style={{
                    marginTop: 5,
                    padding: "7px 16px",
                    fontSize: "18px",
                    backgroundColor: "#2C6C90",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val, "KKKK");
                    handleDetailClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
            </div>
          ),
        };
      });
    setListPencairanAll(tmp || []);
  }, [dataInsentive.pencairanALLDataAM]);

  const getTipePengajuanStyle = (tipePengajuan) => {
    switch (tipePengajuan) {
      case "New":
        return {
          fontWeight: "bold",
          backgroundColor: "#FBF6EC",
          textColor: "#AD8945",
        };
      case "Top Up":
        return {
          fontWeight: "bold",
          backgroundColor: "#D7E8EC",
          textColor: "#376B8A",
        };
      case "Kompensasi":
        return {
          fontWeight: "bold",
          backgroundColor: "#D7E8EC",
          textColor: "#376B8A",
        };
      case "Take Over":
        return {
          fontWeight: "bold",
          backgroundColor: "#D7E8EC",
          textColor: "#376B8A",
        };
      case "Pelunasan di pengajuan":
        return {
          fontWeight: "bold",
          backgroundColor: "#D7E8EC",
          textColor: "#376B8A",
        };
      case "Belum Ada":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6E6",
          textColor: "#FF0000",
        };
      default:
        return {};
    }
  };

  const getStatusUser = (statusUser) => {
    switch (statusUser) {
      case "tidak aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      default:
        return {};
    }
  };

  const stylesInsentif = (kosong) => {
    switch (kosong) {
      case "Belum Ada":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6E6",
          textColor: "#FF0000",
        };
      default:
        return {};
    }
  };

  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
  };

  const colPencairanAll = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + dataInsentive.limitPagePencairanAll.pagingCounter,
    },
    {
      name: "Kode User",
      minWidth: "290px",
      center: true,
      selector: (row) => row.kodeUser,
    },
    {
      name: "Nama",
      minWidth: "290px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Nopeg",
      minWidth: "290px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Jabatan",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.role === "area manager" ? "Area Manager" : "Area Manager",
    },
    {
      name: "New",
      minWidth: "390px",
      center: true,
      selector: (row) =>
        `${row.New.length} Nasabah - ${formatRupiah(
          row.New.insentive
        )} - ${formatRupiah(row.New.totalNominal)}`,
    },
    {
      name: "Top Up",
      minWidth: "390px",
      center: true,
      selector: (row) =>
        `${row.TopUp.length} Nasabah - ${formatRupiah(
          row.TopUp.insentive
        )} - ${formatRupiah(row.TopUp.totalNominal)}`,
    },
    {
      name: "Kompensasi",
      minWidth: "390px",
      center: true,
      selector: (row) =>
        `${row.Kompensasi.length} Nasabah - ${formatRupiah(
          row.Kompensasi.insentive
        )} - ${formatRupiah(row.Kompensasi.totalNominal)}`,
    },
    {
      name: "Take Over",
      minWidth: "390px",
      center: true,
      selector: (row) =>
        `${row.TakeOver.length} Nasabah - ${formatRupiah(
          row.TakeOver.insentive
        )} - ${formatRupiah(row.TakeOver.totalNominal)}`,
    },
    {
      name: "Pelunasan di pengajuan",
      minWidth: "390px",
      center: true,
      selector: (row) =>
        `${row.PelunasanDiPengajuan.length} Nasabah - ${formatRupiah(
          row.PelunasanDiPengajuan.insentive
        )} - ${formatRupiah(row.PelunasanDiPengajuan.totalNominal)}`,
    },
    // {
    //   name: "Total Keseluruhan",
    //   minWidth: "290px",
    //   center: true,
    //   cell: (row) => formatRupiah(row.totalKeseluruhan),
    // },
    {
      name: "Total Insentive",
      minWidth: "290px",
      center: true,
      cell: (row) => formatRupiah(row.totalInsentive),
    },
    {
      name: "Status",
      minWidth: "150px",
      center: true,
      // selector: (row) => (row.status === "aktif" ? "Aktif" : "Tidak Aktif"),
      selector: (row) => row.status,
      cell: (row) => {
        const statusStyle = getStatusUser(row.status);
        const tampilanLabel = row.status === "aktif" ? "Aktif" : "Tidak Aktif";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "250px",
      omit: auth.role === "super admin" || auth.role === "admin" ? false : true,
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    pencairanAllAM(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setLoading(false);
        setStatusCode(response.data.status);
        if (response.data.status === 201) {
          setListPencairanAll([]);
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman, dariTgl, smpTgl]);

  return (
    <>
      {dataInsentive.modalDetailInsentiveAM ? (
        <>
          <Card.Header>
            <Card.Title
              as="h4"
              style={{
                color: "black",
                fontWeight: "bold",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              List intensif Area Manager
            </Card.Title>
          </Card.Header>
          <hr></hr>
          {loading ? (
            <div
              className="loader-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
              <p>Loading...</p>
            </div>
          ) : statusCode === 201 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>
                <b style={{ fontSize: 20 }}>{errorMessage}</b>
              </p>
              <img
                src={sorryImage}
                alt="Sorry"
                style={{
                  maxWidth: "40%",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </div>
          ) : (
            <>
              <Row className="mb-3">
                <Col md="3">
                  <h4
                    className="title mt-2"
                    style={{ fontWeight: "bold", fontSize: 16 }}
                  >
                    Pilih Bulan
                  </h4>
                  <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        readOnly: true,
                        style: {
                          cursor: "default",
                          color: "black",
                          backgroundColor: "white",
                        },
                      }}
                      onChange={(e) => handleDariTglChange(e.toDate())}
                      value={dariTgl}
                      initialValue={dariTgl}
                      timeFormat={false}
                      dateFormat="MM/YYYY" // Menampilkan hanya bulan dan tahun
                    ></ReactDatetime>
                  </div>
                </Col>
              </Row>
              <Card.Body>
                <div>
                  <DataTable
                    columns={colPencairanAll}
                    data={listPencairanAll}
                    customStyles={customStyles}
                    paginationPerPage={totalPerHalaman}
                    // progressPending={pending}
                  />
                  <div style={{ marginTop: 35 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col>
                        <Row style={{ alignItems: "center" }}>
                          <div
                            style={{
                              paddingRight: 0,
                              marginLeft: 20,
                              marginRight: 10,
                            }}
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Rows per page
                            </label>
                          </div>
                          <Col sm="3" style={{ paddingLeft: 0 }}>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="Pilih Client"
                              value={options.find(
                                (option) => option.value === totalPerHalaman
                              )}
                              onChange={handlePageSizeChange}
                              options={options}
                              placeholder="Rows per page"
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col>
                        <ReactPaginate
                          forcePage={page}
                          containerClassName="paginations justify-content-end"
                          previousClassName="pages-items-containers"
                          previousLinkClassName="pages-links-labels"
                          nextClassName="pages-items-containers"
                          nextLinkClassName="pages-links-labels"
                          breakClassName="pages-items-containers"
                          pageCount={
                            dataInsentive.limitPagePencairanAllAM.totalPages
                          }
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={1}
                          pageLinkClassName="pages-links-labels"
                          pageClassName="pages-items-containers"
                          activeClassName="activees"
                          activeLinkClassName="activess"
                          nextLabel=" > "
                          previousLabel=" < "
                          initialPage={page}
                          disableInitialCallback={true}
                          onPageChange={(val) => {
                            setPage(val.selected);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card.Body>
            </>
          )}
        </>
      ) : (
        <>
          <DetailAreaManager data={semuaVal} />
        </>
      )}
    </>
  );
}
export default PenggajianAreaManager;
