import React from "react";
import { Button, Card, Form, Col, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import Moment from "moment";

function Step3() {
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = pengajuan.detailPengajuan;
  const lamaKerjaUsaha = Moment(valDetail.stepTiga.lamaKerjaUsaha).format(
    "DD MMMM YYYY"
  );

  return (
    <Col md="12" style={{ marginTop: 20 }}>
      <Card.Header>
        <Card.Title
          as="h4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          informasi pekerjaan
          <hr></hr>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card style={{ border: 0 }}>
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            <div className="row">
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nama pekerjaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.namaPekerjaan}
                  </Label>
                </li>
              </div> */}
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  status pekerjaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.statusPekerjaan === "pegawai"
                      ? "Pegawai"
                      : valDetail.stepTiga.statusPekerjaan === "pensiun"
                      ? "Pensiun"
                      : "Wirausaha"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nama instansi
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.namaInstansi}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat pekerjaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.alamatPekerjaan}
                  </Label>
                </li>
              </div>
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  telepon kantor
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.telpKantor}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat kantor
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.alamatKantor}
                  </Label>
                </li>
              </div> */}
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  kategori jabatan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.kategoriJabatan === "19"
                      ? "Lainnya"
                      : "Lainnya"}
                  </Label>
                </li>
              </div>
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  pangkat golongan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.pangkatGolongan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  lama kerja usaha
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {lamaKerjaUsaha}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  posisi jabatan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.posisiJabatan}
                  </Label>
                </li>
              </div> */}
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  penghasilan bulanan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {`Rp ${valDetail.stepTiga.penghasilanBulanan.toLocaleString(
                      "id-ID"
                    )}`}
                  </Label>
                </li>
              </div>
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nama usaha
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.namaUsaha}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  bidang usaha
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.bidangUsaha}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat usaha
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepTiga.alamatUsaha}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  omset perbulan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {`Rp ${valDetail.stepTiga.omsetPerBulan.toLocaleString(
                      "id-ID"
                    )}`}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  keuntungan perbulan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {`Rp ${valDetail.stepTiga.keuntunganPerBulan.toLocaleString(
                      "id-ID"
                    )}`}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  pengeluaran perbulan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {`Rp ${valDetail.stepTiga.pengeluaranPerBulan.toLocaleString(
                      "id-ID"
                    )}`}
                  </Label>
                </li>
              </div> */}
            </div>
          </div>
        </Card>
      </Card.Body>
    </Col>
  );
}
export default Step3;
