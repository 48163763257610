import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { number } from "prop-types";
import DetailPengajuan from "./TabPengajuan/indexSteps";
import PengajuanLama from "./TabPengajuan/pengajuanLama";
import { useDispatch, useSelector } from "react-redux";
import { getAllPengajuan } from "stores/Pengajuan/function";
import { getAllStatusPengajuan } from "stores/Pengajuan/function";
import {
  Dna,
  InfinitySpin,
  LineWave,
  Oval,
  Hourglass,
} from "react-loader-spinner";
import { cekStatusPengajuan } from "stores/Pengajuan/function";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { getPengajuanByIdPengajuan } from "stores/Pengajuan/function";
import { pencairanAll } from "stores/Pengajuan/function";

function Pengajuan() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const modalDetailPengajuan = useSelector((state) => state.pengajuanReducer);
  // console.log(modalDetailPengajuan, "AAAA");
  const auth = useSelector((state) => state.authReducer);
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const dispatch = useDispatch();
  const [listPengajuan, setListPengajuan] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [listStatus, setListStatus] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [idPengajuan, setIdPengajuan] = React.useState("");
  const [modalCekStatus, setModalCekStatus] = React.useState(false);
  const [hasilCekStatus, setHasiCekStatus] = React.useState(null);
  const [detailPengajuan, setDetaiPengajuan] = React.useState(null);
  const [pending, setPending] = React.useState(false);
  //Testing Pagination
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
    setPage(0);
  };
  // console.log(handlePageSizeChange, "KKK");

  const handleFilterChange = () => {
    setPending(true);

    getAllPengajuan(dispatch, {
      status: pilihOpsi,
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleDetailsClick = (val) => {
    // console.log(val, "YYYY");
    dispatch({ type: "SET_DETAIL_PENGAJUAN", data: val });

    getPengajuanByIdPengajuan(dispatch, {
      idPengajuan: val.id_pinjaman,
    }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
        dispatch({ type: "SET_DETAIL_PENGAJUAN", data: response.data.data[0] });
      }
    });
  };

  const hidePengajuanLama = () => {
    dispatch({ type: "SET_PENGAJUAN_LAMA", data: false });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  React.useEffect(() => {
    if (pengajuan.listPengajuan.data) {
      let tmp = pengajuan.listPengajuan.data.map((val) => {
        let tmpTanggalPengajuanBpd = "";
        let updateBy = "";
        for (var i = 0; i < val.history.length; i++) {
          if (val.history[i].status === "Diproses") {
            tmpTanggalPengajuanBpd = val.history[i].date;
            break;
          }
        }
        for (var i = 0; i < val.history.length; i++) {
          if (val.history[i].status === "Disetujui") {
            updateBy = val.history[i].keterangan;
            break;
          }
        }

        return {
          ...val,
          namaCabangPencairan: !val.lokasiPencairan
            ? "-"
            : val.lokasiPencairan.namaCabang,
          tanggalPengajuanBpd:
            tmpTanggalPengajuanBpd === ""
              ? "-"
              : moment(new Date(tmpTanggalPengajuanBpd)).format("DD MMMM YYYY"),
          tanggalPencairan:
            val.statusPengajuan === "Disetujui"
              ? moment(new Date(val.tglAkad)).format("DD MMMM YYYY")
              : "-",
          diupdateOleh: updateBy === "" ? "-" : updateBy,
          action: (
            <>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Details Pengajuan</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#30B9FB",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val, "TTTT");
                    handleDetailsClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>{" "}
              <>
                <Button
                  disabled={
                    val.statusPengajuan !== "Diproses" &&
                    val.statusPengajuan !== "Diajukan"
                  }
                  className="ml-2"
                  style={{
                    cursor:
                      val.statusPengajuan !== "Diproses" &&
                      val.statusPengajuan !== "Diajukan"
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      val.statusPengajuan !== "Diproses" &&
                      val.statusPengajuan !== "Diajukan"
                        ? "grey"
                        : "#FF6A49",
                    borderWidth: 0,
                    marginTop: 5,
                    // backgroundColor: "#53ABA0",
                    border: 0,
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    let siapCek = {
                      idPinjaman: val.id_pinjaman,
                      nik: val.nomorIdentitas,
                    };
                    Swal.fire({
                      title: "Loading ...",
                      allowOutsideClick: false,
                      showConfirmButton: false,
                      onBeforeOpen: () => {
                        Swal.showLoading();
                      },
                    });

                    cekStatusPengajuan(siapCek)
                      .then((response) => {
                        Swal.close();

                        // console.log(response, "OOO");
                        if (response.status === 200) {
                          Swal.fire({
                            title: "Berhasil",
                            text: response.data.message,
                            icon: "success",
                          });
                          getAllPengajuan(dispatch, {
                            status:
                              filterStatus === null || filterStatus.value === ""
                                ? ""
                                : filterStatus.value,
                            cari: "",
                            page: page + 1,
                            limit: totalPerHalaman,
                          });
                          setModalCekStatus(!modalCekStatus);
                          setDetaiPengajuan(val);
                          setHasiCekStatus(response.data);
                        } else {
                          Swal.fire({
                            title: "Gagal",
                            text: "Gagal melakukan cek status",
                            icon: "error",
                          });
                        }
                      })
                      .catch((error) => {
                        // Menutup loading Swal jika terjadi kesalahan
                        Swal.close();
                        console.error("Error:", error);
                      });
                  }}
                >
                  Cek Status
                </Button>
              </>
            </>
          ),
        };
      });
      // console.log(tmp, "KKK");
      setListPengajuan(tmp);
    }
  }, [pengajuan.listPengajuan.data]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  React.useEffect(() => {
    let tmp = [
      { value: "", label: "All" },
      {
        value: "Menunggu Konfirmasi",
        label: "Menunggu Konfirmasi",
      },
      {
        value: "Diajukan",
        label: "Diajukan",
      },
      {
        value: "Diajukan (File Gagal Terkirim)",
        label: "Diajukan (File Gagal Terkirim)",
      },
      {
        value: "Diproses",
        label: "Diproses",
      },
      {
        value: "Disetujui",
        label: "Disetujui",
      },
      {
        value: "Revisi",
        label: "Revisi",
      },
      {
        value: "Ditolak",
        label: "Ditolak",
      },
    ];
    setListStatus(tmp);
  }, [pengajuan.listPengajuan.data]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "Menunggu Konfirmasi":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFF5CD",
          textColor: "#FFAF05",
        };
      case "Diajukan":
        return {
          fontWeight: "bold",
          backgroundColor: "#BAD9FD",
          textColor: "#1776F1",
        };
      case "Diajukan (File Gagal Terkirim)":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3CFD4",
          textColor: "#76424E",
        };
      case "Diproses":
        return {
          fontWeight: "bold",
          backgroundColor: "#FDDACB",
          textColor: "#F84B02",
        };
      case "Disetujui":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3FCDB",
          textColor: "#5AC967",
        };
      case "Revisi":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE2E6",
          textColor: "#FF2B4B",
        };
      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#E10F00",
          textColor: "#ffffff",
        };
      default:
        return {};
    }
  };

  // const getStatusStyleSelect = (status) => {
  //   switch (status) {
  //     case "Menunggu Konfirmasi":
  //       return { textColor: "#FFAF05" };
  //     case "Diajukan":
  //       return { textColor: "#1776F1" };
  //     case "Diproses":
  //       return { textColor: "#fa5d02" };
  //     case "Disetujui":
  //       return { textColor: "#5AC967" };
  //     case "Revisi":
  //       return { textColor: "#FF2B4B" };
  //     case "Ditolak":
  //       return { textColor: "#E10F00" };
  //     default:
  //       return {};
  //   }
  // };

  // const customStylesSelect = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: getStatusStyleSelect(state.label).backgroundColor,
  //     color: getStatusStyleSelect(state.data.label).textColor,
  //     padding: "8px",
  //     borderRadius: "4px",
  //   }),
  // };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
  };

  const colPengajuan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => index + pengajuan.listPengajuan.pagingCounter,
      // (currentPage - 1) * totalPerHalaman +
      // pengajuan.listPengajuan.pagingCounter,
    },
    {
      name: "Referensi ID",
      minWidth: "390px",
      center: true,
      selector: (row) => row.id_pinjaman,
    },
    {
      name: "Calon Nasabah",
      minWidth: "270px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "Cabang Pencairan",
      minWidth: "250px",
      center: true,
      selector: (row) => row.namaCabangPencairan,
    },
    {
      name: "Marketing",
      minWidth: "250px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Supervisor",
      minWidth: "250px",
      center: true,
      selector: (row) => row.supervisor.nama,
    },
    {
      name: "Tanggal Pengajuan Ke Supervisor",
      minWidth: "300px",
      center: true,
      selector: (row) => moment(new Date(row.createdAt)).format("DD MMMM YYYY"),
    },
    {
      name: "Tanggal Pengajuan Ke BPD",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPengajuanBpd,
    },
    {
      name: "Tanggal Pencairan",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPencairan,
    },
    // {
    //   name: "Diupdate Oleh",
    //   minWidth: "300px",
    //   center: true,
    //   selector: (row) => row.diupdateOleh,
    // },
    {
      name: "Status",
      minWidth: "300px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setPending(true);

    getAllPengajuan(dispatch, {
      status:
        filterStatus === null || filterStatus.value === ""
          ? ""
          : filterStatus.value,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setPending(false);
      })
      .catch((error) => {
        // setLoading(false);
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [modalDetailPengajuan.modalDetailPengajuan, page, totalPerHalaman]);

  // React.useEffect(() => {
  //   getAllPengajuan(dispatch, { status: "" });
  // }, [modalDetailPengajuan.modalDetailPengajuan]);

  return (
    <>
      <Modal
        size="lg"
        show={modalCekStatus}
        onHide={() => setModalCekStatus(!modalCekStatus)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Cek Status Pengajuan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {hasilCekStatus &&
              (hasilCekStatus.status === "101" ||
                hasilCekStatus.status === "200") ? (
                <>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Refference ID
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.id_pinjaman}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Nama Nasabah
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.namaLengkap}
                      </label>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Status Pengajuan
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {hasilCekStatus && hasilCekStatus.keterangan}
                      </label>
                    </Col>
                  </Row> */}
                </>
              ) : (
                <>
                  <Row>
                    <Col sm="3">
                      <li>
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          Refference ID
                        </label>
                      </li>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.id_pinjaman}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <li>
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          Nama Nasabah
                        </label>
                      </li>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.namaLengkap}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <li>
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          Pesan
                        </label>
                      </li>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {hasilCekStatus && hasilCekStatus.message}
                      </label>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>

            <Card.Footer></Card.Footer>
          </Card>
        </Col>
      </Modal>

      {modalDetailPengajuan.pengajuanLama ? (
        <>
          {modalDetailPengajuan.modalDetailPengajuan ? (
            <>
              <Container fluid>
                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Header>
                        <Card.Title
                          as="h3"
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          List Pengajuan
                        </Card.Title>
                        <Tab.Content>
                          <Card style={{ border: 0, marginTop: 20 }}>
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <Col md={"4"}>
                                <InputGroup>
                                  <Form.Control
                                    style={{
                                      paddingLeft: "10px",
                                    }}
                                    type="text"
                                    value={searchText}
                                    onChange={handleSearch}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Reffrence ID/Nasabah"
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text
                                      style={{ cursor: "pointer" }}
                                      onClick={handleClearSearch}
                                    >
                                      <i class="fas fa-times"></i>
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>

                              <Col md={"3"}>
                                <div>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Status"
                                    value={filterStatus}
                                    onChange={(selectedOption) => {
                                      setFilterStatus(selectedOption);
                                      setPilihOpsi(selectedOption.value);
                                    }}
                                    defaultValue={{
                                      label: "All",
                                      value: "",
                                    }}
                                    options={[...listStatus]}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Pilih Status"
                                  />
                                </div>
                              </Col>
                              <Row>
                                <Col>
                                  <Button
                                    // variant="info"
                                    style={{
                                      fontSize: 16,
                                      backgroundColor: "#00BFFF",
                                      border: 0,
                                      paddingTop: 10,
                                      paddingBottom: 7,
                                    }}
                                    onClick={handleFilterChange}
                                  >
                                    <i class="fas fa-search"></i>
                                  </Button>
                                </Col>
                                {/* <Col> */}
                                {auth.role === "super admin" ||
                                auth.role === "admin" ? (
                                  <>
                                    <Button
                                      className="mr-1"
                                      // variant="primary"
                                      style={{
                                        marginBottom: 10,
                                        fontSize: 16,
                                        backgroundColor: "#BBC9D3",
                                        color: "#425A6B",
                                        border: 0,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        textTransform: "capitalize",
                                        fontWeight: "bold",
                                      }}
                                      onClick={hidePengajuanLama}
                                    >
                                      pengajuan lama
                                    </Button>
                                  </>
                                ) : null}
                              </Row>
                            </Row>
                          </Card>
                        </Tab.Content>
                      </Card.Header>
                      <Card.Body>
                        {pending ? (
                          <div
                            className="loader-container"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <div style={{ marginBottom: 20 }}>
                              <Hourglass
                                visible={true}
                                height="50"
                                width="50"
                                ariaLabel="hourglass-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                colors={["#306cce", "#72a1ed"]}
                              />
                              {/* <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 19,
                          }}
                        >
                          Loading ....
                        </p> */}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <DataTable
                              columns={colPengajuan}
                              data={listPengajuan}
                              customStyles={customStyles}
                              paginationPerPage={totalPerHalaman}
                              progressPending={pending}
                            />
                            <div style={{ marginTop: 35 }}>
                              <Row
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Col>
                                  <Row style={{ alignItems: "center" }}>
                                    <div
                                      style={{
                                        paddingRight: 0,
                                        marginLeft: 20,
                                        marginRight: 10,
                                      }}
                                    >
                                      <label style={{ fontWeight: "bold" }}>
                                        Rows per page
                                      </label>
                                    </div>
                                    <Col sm="3" style={{ paddingLeft: 0 }}>
                                      <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="Pilih Client"
                                        value={options.find(
                                          (option) =>
                                            option.value === totalPerHalaman
                                        )}
                                        onChange={handlePageSizeChange}
                                        options={options}
                                        placeholder="Rows per page"
                                      />
                                    </Col>
                                  </Row>
                                </Col>

                                <Col>
                                  <ReactPaginate
                                    forcePage={page}
                                    containerClassName="paginations justify-content-end"
                                    previousClassName="pages-items-containers"
                                    previousLinkClassName="pages-links-labels"
                                    nextClassName="pages-items-containers"
                                    nextLinkClassName="pages-links-labels"
                                    breakClassName="pages-items-containers"
                                    pageCount={
                                      pengajuan.listPengajuan.totalPages
                                    }
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageLinkClassName="pages-links-labels"
                                    pageClassName="pages-items-containers"
                                    activeClassName="activees"
                                    activeLinkClassName="activess"
                                    nextLabel=" > "
                                    previousLabel=" < "
                                    initialPage={page}
                                    disableInitialCallback={true}
                                    onPageChange={(val) => {
                                      setPage(val.selected);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <DetailPengajuan />
            </>
          )}
        </>
      ) : (
        <PengajuanLama />
      )}
    </>
  );
}

export default Pengajuan;
