import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reduxSoldierMiddleware } from "redux-soldier";
//import axios from "axios";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import monitoringReducer from "./monitoring";
import userReducer from "./user";
import clientReducer from "./client";
import korwilReducer from "./korwil";
import areaManagerReducer from "./areaManager";
import marketingReducer from "./marketing";
import supervisorReducer from "./supervisor";
import cabangReducer from "./cabang";
import pengajuanReducer from "./Pengajuan";
import dashboardUtamaReducer from "./dashboardUtama";
import managementFeeReducer from "./ManagementFee";

// function
export * from "./auth/function";
export * from "./monitoring/function";
export * from "./errorHandler";
export * from "./user/function";
export * from "./client/function";
export * from "./korwil/function";
export * from "./areaManager/function";
export * from "./marketing/function";
export * from "./supervisor/function";
export * from "./cabang/function";
export * from "./dashboardUtama/function";
export * from "./ManagementFee/function";

// server api
// export const baseUrl = process.env.REACT_APP_URL
export const baseUrl = "https://apikalsel.ptbap.net/";
// export const baseUrl = "https://newapibpd.waroengapp.my.id/";
// export const baseUrl = "http://192.168.100.51:9030/";
// export const baseUrl = "http://192.168.110.160:9050/";
// export const baseUrl = "https://t5w2szrw-9050.asse.devtunnels.ms/";
// export const baseUrl = "http://192.168.100.51:9050/";
// export const baseUrl = "http://192.168.110.44:9050/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  //timeout: 10000,
});

const rootReducer = combineReducers({
  userReducer,
  authReducer,
  clientReducer,
  dashboardReducer,
  monitoringReducer,
  korwilReducer,
  areaManagerReducer,
  marketingReducer,
  supervisorReducer,
  cabangReducer,
  pengajuanReducer,
  dashboardUtamaReducer,
  managementFeeReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);
